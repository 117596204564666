import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import Transfer from './transfer';

class TransferController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTransfering: false,
			isDownloadingStorageFiles: false,
			isUploading: false,
			isUploading2: false,
			didTransfer: false,
			areasStatus: false,
			decksStatus: false,
			cardsStatus: false,
			missionsStatus: false,
			surveysStatus: false,
			imagesStatus: false,
			tagsStatus: false,
			downloadingDBCollection: null,
			errorMsg: null,
			feedbackMsg: null,
			fileToUpload: null,
		};
	};

	/**
	 * Transfer database data
	 */
	transferDataAndImages = () => {
		if (this.state.isTransfering) return;
		this.setState({
			isTransfering: true, 
			didTransfer: false, 
			areasStatus: false,
			decksStatus: false, 
			cardsStatus: false, 
			missionsStatus: false,
			surveysStatus: false,
			imagesStatus: false,
			tagsStatus: false
		}, () => {
			this.transferAreas().then(() => {
				this.transferDecks().then(() => {
					this.transferCards().then(() => {
						this.transferMissions().then(() => {
							this.transferSurveys().then(() => {
								this.transferImages().then(() => {
									this.transferTags().then(() => {
										this.setState({isTransfering: false, didTransfer: true});
									}, (error) => {console.log(error);});
								}, (error) => {console.log(error);});
							}, (error) => {console.log(error);});
						}, (error) => {console.log(error);});
					}, (error) => {console.log(error);});
				}, (error) => {console.log(error);});
			}, (error) => {console.log(error);});
		});
	}

	/* Transfer areas */
	transferAreas = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('areas').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let areaObj = doc.data();
					let areaRef = db.collection('areas-demo').doc(doc.id);
					batch.set(areaRef, areaObj);
				});
				batch.commit().then(() => {
					this.setState({areasStatus: true}, () => {
						resolve();
					});
				}, (error) => {reject(error);});
			});
		});
	}


	/* Transfer decks */
	transferDecks = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('decks').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let deckObj = doc.data();
					let deckRef = db.collection('decks-demo').doc(doc.id);
					batch.set(deckRef, deckObj);
				});
				batch.commit().then(() => {
					this.setState({decksStatus: true}, () => {
						resolve();
					});
				}, (error) => {reject(error);});
			});
		});
	}

	/* Transfer cards */
	transferCards = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('cards').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let cardObj = doc.data();
					let cardRef = db.collection('cards-demo').doc(doc.id);
					batch.set(cardRef, cardObj);
				});

				batch.commit().then(() => {
					this.setState({cardsStatus: true}, () => {
						resolve();
					});

				}, (error) => {reject(error);});
			});
		});
	}

	/* Transfer missions */
	transferMissions = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('missions').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let missionObj = doc.data();
					let missionRef = db.collection('missions-demo').doc(doc.id);
					batch.set(missionRef, missionObj);
				});

				batch.commit().then(() => {
					this.setState({missionsStatus: true}, () => {
						resolve();
					});

				}, (error) => {reject(error);});
			});
		});
	}

	/* Transfer surveys */
	transferSurveys = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('surveys').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let surveyObj = doc.data();
					let surveyRef = db.collection('surveys-demo').doc(doc.id);
					batch.set(surveyRef, surveyObj);
				});

				batch.commit().then(() => {
					this.setState({surveysStatus: true}, () => {
						resolve();
					});

				}, (error) => {reject(error);});
			});
		});
	}

	/* Transfer image references */
	transferImages = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('images').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let imageObj = doc.data();
					let imageRef = db.collection('images-demo').doc(doc.id);
					batch.set(imageRef, imageObj);
				});
				batch.commit().then(() => {
					this.setState({imagesStatus: true}, () => {
						resolve();
					});
				}, (error) => {reject(error);});
			});
		});
	}

	/* Transfer tags  */
	transferTags = () => {
		return new Promise((resolve, reject)=>{
			const db = firebase.firestore();
			let batch = db.batch();
			db.collection('tags').get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					let tagObj = doc.data();
					let tagRef = db.collection('tags-demo').doc(doc.id);
					batch.set(tagRef, tagObj);
				});
				batch.commit().then(() => {
					this.setState({tagsStatus: true}, () => {
						resolve();
					});
				}, (error) => {reject(error);});
			});
		});
	}

	/**
	 * Download database collection
	 */
	downloadDBData = (collectionName) => {
		if (this.state.downloadingDBCollection) return;
		this.setState({downloadingDBCollection: collectionName + 'Data'}, () => {
			const db = firebase.firestore();
			db.collection(collectionName).get().then((docs) => {
				let documents = [];
				docs.forEach((doc) => {documents.push({...doc.data(), id: doc.id});});
	
				let collection = {
					timestamp: Math.floor(Date.now() / 1000),
					collectionId: collectionName,
					documents: documents
				};
				
				let json = JSON.stringify(collection, null, 4);
				let blob = new Blob([json], {type: 'text/json'});
				let url = window.URL.createObjectURL(blob);
				let link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', collectionName + 'Data.json');
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
				this.setState({downloadingDBCollection: null});
			}).catch((error) => {
				console.error(error);
				this.setState({downloadingDBCollection: null});
			});
		});
	}


	downloadStorageFiles= () => {
		if (this.state.isDownloadingStorageFiles) return;
		this.setState({isDownloadingStorageFiles: true}, () => {
			const db = firebase.firestore();
			db.collection('images').get().then((docs) => {
				let filesToDownload = [];
				docs.forEach((doc) => {
					let imageData = doc.data();
					if (imageData && imageData.fileName && imageData.location) {
						filesToDownload.push({
							location: 'bucket',
							path: imageData.location + '/' + imageData.fileName,
							fileName: imageData.fileName,
						});
					}
				});
				if (filesToDownload.length > 0) {
					this.zipAndDownloadFiles('storage', filesToDownload).then(() => {
						this.setState({isDownloadingStorageFiles: false});
					}).catch((error) => {
						console.error(error);
						this.setState({isDownloadingStorageFiles: false});
					});
				}
			}, (error) => {
				console.error(error);
				this.setState({isDownloadingStorageFiles: false});
			});
		});
	}

	/**
	 * Zip files, save on server and download
	 * @param {string} outputName 
	 * @param {array} files 
	 */
	zipAndDownloadFiles = (outputName, files) => {
		return new Promise((resolve, reject) => {
			let url = appConfig.apiURL + 'zip-and-download-files';
			let body = {outputName: outputName, files: files};

			var myHeaders = new Headers();
			myHeaders.append('Content-Type', 'application/json');

			let fetchConfig = {
				method: 'POST',
				mode: 'cors',
				headers: myHeaders,
				cache: 'default',
				body: JSON.stringify(body)
			};

			fetch(url, fetchConfig).then(
				(response)=>{
					response.blob().then((blob) => {
						const url = window.URL.createObjectURL(new Blob([blob]));
						const link = document.createElement('a'); 
						link.href = url;
						link.setAttribute('download', outputName + '.zip');
						document.body.appendChild(link);
						link.click();
						link.parentNode.removeChild(link);
						resolve();
					});
				},
				(error) => {
					console.error(error);
					reject();
				}
			);
		});
	}

	/**
	 * Select json-file
	 * @param {object} event 
	 */
	selectFileToUpload = (event) => {
		if (event.target.files.length > 1) {
			let feedbackMsg = 'Choose 1 file only!';
			this.setState({errorMsg: feedbackMsg});
		} else {
			this.setState({fileToUpload: event.target.files[0]});
		}
	}

	uploadTextToDatabase = () => {
		let fileToUpload = this.state.fileToUpload;
		if (!fileToUpload || this.state.isUploading) return;
		this.setState({isUploading: true}, () => {
			this.readJSONFile(fileToUpload).then((file)=> {	
				if (file.hasOwnProperty('collectionId') && file.hasOwnProperty('documents')) {
					let collectionId = file.collectionId;
					const db = firebase.firestore();
					let batch = db.batch();
					// Update each document in collection
					file.documents.forEach((document) => {
						// const documentId = document.id;
						let documentUpdates = null;

						/* Areas & decks */
						if (collectionId === 'areas' || collectionId === 'decks') {
							if (document.title) {
								documentUpdates = {title: document.title};
							}
						}

						/* Surveys */
						// if (collectionId === 'surveys') {
						// 	db.collection(collectionId).doc(documentId).get()
						// 	if (document.title) {
						// 		documentUpdates = {title: document.title};
						// 		if (document.steps) {
						// 			let steps = JSON.parse(JSON.stringify(document.steps));

						// 		}
						// 	}
						// }


						if (documentUpdates) {
							console.log(documentUpdates);
							// const documentRef = db.collection(collectionId).doc(documentId);
							// batch.update(documentRef, documentUpdates);
						}
					});
	
					// Commit batch
					batch.commit().then(() => {
						this.setState({
							isUploading: false,
							feedbackMsg: 'Upload successful'
						});
						setTimeout(() => {
							this.setState({ feedbackMsg: null });
						}, 3000);
					}).catch((error) => {
						console.error(error);
						this.setState({
							isUploading: false,
							feedbackMsg: 'Something went wrong'
						});
					});
				} else {
					this.setState({isUploading: false});
					console.error('wrong format');
				}
			});
		});
	}


	/**
	 * Upload json file to database
	 */
	uploadFileToDatabase = () => {
		let fileToUpload = this.state.fileToUpload;
		if (!fileToUpload || this.state.isUploading) return;
		this.setState({isUploading: true}, () => {
			this.readJSONFile(fileToUpload).then((file)=> {	
				if (file.hasOwnProperty('collectionId') && file.hasOwnProperty('documents')) {
					let collectionId = file.collectionId;
					const db = firebase.firestore();
					let batch = db.batch();
					// Update each document in collection
					file.documents.forEach((document) => {
						let documentId = document.id;
						let documentObj = JSON.parse(JSON.stringify(document));
						if (collectionId === 'missions' || collectionId === 'surveys') {
							if (collectionId === 'missions') documentObj.id = parseInt(documentObj.id);
						} else {
							delete documentObj.id;
						}
						let documentRef = db.collection(collectionId).doc(documentId);
						batch.set(documentRef, documentObj);
					});
	
					// Commit batch
					batch.commit().then(() => {
						this.setState({
							isUploading: false,
							feedbackMsg: 'Upload successful'
						});
						setTimeout(() => {
							this.setState({ feedbackMsg: null });
						}, 3000);
					}).catch((error) => {
						console.error(error);
						this.setState({
							isUploading: false,
							feedbackMsg: 'Something went wrong'
						});
					});
				} else {
					this.setState({isUploading: false});
					console.error('wrong format');
				}
			});
		});
	}

	/**
	 * Read json file from client computer
	 * @param {object} file
	 */
	readJSONFile = (file) => {
		return new Promise((resolve, reject)=>{
			let fileReader = new FileReader();
			fileReader.readAsText(file, 'UTF-8');
			fileReader.onerror = (event)=>{
				reject(event.error);
			};
			fileReader.onloadend = (event)=>{
				let textData = event.target.result;
				let data = JSON.parse(textData);
				resolve(data);
			};
		});
	}

	/**
	 * Render component
	 */
	render() {
		return (
			<Transfer
				isTransfering={this.state.isTransfering}
				isDownloadingStorageFiles={this.state.isDownloadingStorageFiles}
				isUploading={this.state.isUploading}
				isUploading2={this.state.isUploading2}
				didTransfer={this.state.didTransfer}
				areasStatus={this.state.areasStatus}
				decksStatus={this.state.decksStatus}
				cardsStatus={this.state.cardsStatus}
				missionsStatus={this.state.missionsStatus}
				surveysStatus={this.state.surveysStatus}
				imagesStatus={this.state.imagesStatus}
				tagsStatus={this.state.tagsStatus}
				feedbackMsg={this.state.feedbackMsg}
				downloadingDBCollection={this.state.downloadingDBCollection}
				transferDataAndImages={this.transferDataAndImages}
				downloadDBData={this.downloadDBData}
				downloadStorageFiles={this.downloadStorageFiles}
				selectFileToUpload={this.selectFileToUpload}
				uploadTextToDatabase={this.uploadTextToDatabase}
				uploadFileToDatabase={this.uploadFileToDatabase}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

TransferController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default TransferController;
import appConfig from 'config/app.config';

const cookieUiTexts = (appConfig.env === 'en' 
	? {
		text: 'The game uses cookies to store the game result.<br />Please approve the game\'s use of cookies.',
		okBtn: 'Ok'	
	}
	: {
	text: 'Spillet bruger cookies til at gemme spilresultatet.<br />Godkend spillets brug af cookies.',
	okBtn: 'Ok'
	}
);

const loginUiTexts = (appConfig.env === 'en' 
	? {
		login: 'Login',
		createNewPlayer: 'Create new player',
		namePlaceholder: 'Name',
		passwordPlaceholder: 'Password',
		passwordRepeatPlaceholder: 'Repeat password',
		backToLogin: 'Back to login',
	}
	: {
		login: 'Login',
		createNewPlayer: 'Opret ny spiller',
		namePlaceholder: 'navn',
		passwordPlaceholder: 'kodeord',
		passwordRepeatPlaceholder: 'gentag kodeord',
		backToLogin: 'Tilbage til login'
	}
);

const errorUiTexts = (appConfig.env === 'en'
	? {
		offline: 'You are offline. Please connect to the internet to play.',
		acceptConditions: 'You must accept conditions',
		emptyFields: 'You must fill in all fields',
		userDoesNotExist: 'User does not exist',
		usernameNotAvailable: 'Username is not available',
		invalidPassword: 'Invalid password',
		invalidEmail: 'Error: Invalid e-mail address',
		passwordsDoNotMatch: 'Your passwords do not match',
		userExists: 'The user already exists',
		nameLength: 'The name must contain 3-15 letters',
		leadingOrTrailingSpaces: 'The name cannot start or end with a space ',
		unknownError: 'Unknown error',
		connectionError: 'There are problems with the connection',
		unknownSurvey: 'Unknown survey',
		continueGame: 'Please continue game',
	}
	: {
		offline: 'Du er offline. Forbind til internettet for at spille.',
		acceptConditions: 'Du skal godkende betingelserne',
		emptyFields: 'Du skal udfylde alle felter',
		userDoesNotExist: 'Brugeren eksisterer ikke',
		usernameNotAvailable: 'Brugernavnet er ikke ledigt',
		invalidPassword: 'Ugyldigt password',
		invalidEmail: 'Fejl: ugyldigt email',
		passwordsDoNotMatch: 'Dine passwords matcher ikke',
		userExists: 'Brugeren eksisterer allerede',
		nameLength: 'Navnet må være på 3-15 bogstaver',
		leadingOrTrailingSpaces: 'Navnet må ikke starte eller slutte med et mellemrum',
		unknownError: 'Ukendt fejl',
		connectionError: 'Der er problemer med forbindelsen',
		unknownSurvey: 'Ukendt survey',
		continueGame: 'Fortsæt spil'
	}
);

const generalUiTexts = (appConfig.env === 'en'
	? {
		title: "Mazeout",
		logout: 'Log out',
		iAccept: 'I approve ',
		conditions: 'conditions',
		startGame: 'Start game',
		continueGame: 'Continue game',
		continuePlaying: 'Continue playing',
		continue: 'Continue',
		ok: 'Okay',	
		close: 'Close',
		yay: 'Yay!',
		missions: 'Missions',
		viewMissions: 'View missions',
		surveys: 'Surveys',
		viewSurveys: 'View surveys',
		new: 'New',
		completed: 'Completed',
		gameoverPopup: {
			text: 'You wake up!',
			buttons: {ok: 'Okay'}
		},
		missionCompletedFeedback: 'You have completed the mission: <span>%missionTitle%</span>',
		lockOpened: 'You have opened a lock in the portal.<br />Find more keys by completing more missions.',
		portalOpened: 'You have opened the portal to a new area in the maze. Here you will gain new experiences and be able to solve new missions.',
		infoPopup: {
			text: '<p> Mazeout is about exploring a maze-like dream, it resembles your life, but not completely. Along the way, you must make choices by swiping right or left. No choices are right or wrong.</p><p>By trying out different options, you solve missions. Each mission you solve opens a lock in a portal. For each portal you open, you advance in the maze. You complete the game by getting completely through the five areas of the maze.</p>',
			buttonText: 'Close'
		},
		endOfGamePopup: {
			text: 'That’s it! You have completed the last exercise. You have come through the maze and the world waits in front of you.',
			text2: 'Now you can explore the game, because even if you made it through the maze, there are still many choices to be made. Tap "Continue" to explore new possibilities.',
			buttonText: 'Okay'
		},
		freeplayPopup: {
			text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam',
		},
		surveysPopup: {
			text: 'During Mazeout you will be presented with a series of reflection exercises. If you choose to use the exercises and feel like rereading your thoughts, you can e-mail them to yourself below:',
			text2: 'E-mail your reflections to yourself.<br />Write your e-mail address:',
			email: 'E-mail address',
			send: 'Send',
			emailSent: 'E-mail has been sent! Please check your inbox.'
		}
	}
	: {
		title: "Mazeout",
		logout: 'Log ud',
		iAccept: 'Jeg godkender ',
		conditions: 'betingelserne',
		startGame: 'Start spillet',
		continueGame: 'Fortsæt spillet',
		continuePlaying: 'Spil videre',
		continue: 'Gå videre',
		ok: 'Ok',	
		close: 'Luk',
		yay: 'Yay!',
		missions: 'Missioner',
		viewMissions: 'Se missioner',
		surveys: 'Øvelser',
		viewSurveys: 'Se øvelser',
		new: 'Nye',
		completed: 'Fuldførte',
		gameoverPopup: {
			text: 'Du vågner!',
			buttons: {ok: 'Ok'}
		},
		missionCompletedFeedback: 'Du har fuldført missionen: <span>%missionTitle%</span>',
		lockOpened: 'Du har åbnet en lås i portalen.<br />Find flere nøgler ved at klare flere missioner.',
		portalOpened: 'Du har åbnet portalen til et nyt område i labyrinten. Her vil du få nye oplevelser og kunne løse nye missioner.',
		infoPopup: {
			text: '<p>Mazeout handler om at udforske en labyrintisk drøm, den ligner dit liv, men ikke helt. Undervejs skal du tage valg ved at swipe til højre eller venstre. Der er ikke nogen valg, der er rigtige eller forkerte.</p><p>Ved at afprøve forskellige muligheder løser du missioner. Hver mission, du løser, åbner en lås i en portal. For hver portal du åbner, kommer du videre i labyrinten. Du klarer spillet ved at komme helt ud af labyrintens fem områder.</p>',
			buttonText: 'Luk'
		},
		endOfGamePopup: {
			text: 'Sådan! Det var den sidste øvelse. Du er kommet ud af labyrinten, og verden venter foran dig.',
			text2: 'Nu kan du frit gå på opdagelse i spillet, for selvom du kom ud af labyrinten er der stadig mange valg der skal træffes. Tryk på "Spil videre" for at udforske nye muligheder.',
			buttonText: 'Ok'
		},
		surveysPopup: {
			text: 'Undervejs i Mazeout bliver du præsenteret for en række refleksionsøvelser. Hvis du vælger at bruge øvelserne og har lyst til at genlæse dine tanker, kan du sende dem til dig selv nedenfor:',
			text2: 'Send dine refleksioner til dig selv.<br />Skriv din e-mailadresse:',
			email: 'E-mail',
			send: 'Send',
			emailSent: 'E-mail sendt! Tjek din inbox.'
		}
	}
);

const adminUiTexts = {
	back: 'Tilbage',
	stats: 'Statistik'
}

export{
	cookieUiTexts,
	loginUiTexts,
	errorUiTexts,
	generalUiTexts,
	adminUiTexts
};
let imagesData = [
	'animation-key3.gif',
	'background-info.svg',
	'background-intro.jpg',
	'background-login-bottom.svg',
	'background-login-top.svg',
	'background-login.svg',
	'background-night.svg',
	'background-splash-bottom.svg',
	'background-splash-middle.svg',
	'background-splash-top.svg',
	'background-the-end.svg',
	'button-info-hover.svg',
	'button-info.svg',
	'button-login-hover.svg',
	'button-login.svg',
	'button-play-hover.svg',
	'button-play.svg',
	'flower1.svg',
	'flower2.svg',
	'icon-arrow-down.svg',
	'icon-check.svg',
	'icon-close.svg',
	'icon-eye.svg',
	'icon-info.svg',
	'icon-labyrinth.svg',
	'icon-loading-key.svg',
	'logo-cgl.png',
	'logo-region-s.png',
	'logo-tryg.png',
	'title.svg'
];

const cardImages = [
	'feedback.svg',
	'mission.svg'
];

const deckImages = [
	'pink-back.svg',
	'pink-deck.svg',
	'blue-back.svg',
	'blue-deck.svg'
];

const mazeImages = [
	'key-horizontal-ghost.svg',
	'key-horizontal-unlocked.svg',
	'key-horizontal.svg',
	'key-rotated-unlocked.svg',
	'key-rotated.svg',
	'key-vertical-ghost.svg',
	'key-vertical-unlocked.svg',
	'key-vertical.svg',
	'maze-1.svg',
	'maze-2.svg',
	'maze-3.svg'
];

const portalImages = [
	'background-ground.svg',
	'background-portal-1.svg',
	'background-portal-2.svg',
	'background-portal-3.svg',
	'background-portal-4.svg',
	'background-portal-5.svg',
	'gate.svg',
	'key-hole-unlocked.svg',
	'key-hole.svg',
	'maze-left.svg',
	'maze-right.svg'
];

const progressImages = [
	'icon-moon.svg',
	'icon-moon2.svg',
	'icon-sun.svg',
	'maze-floor.svg',
	'maze-portal.svg'
]

const surveyImages = [
	'therapist.svg',
	'therapist-2.svg'
];

cardImages.forEach((image) => {imagesData.push('cards/' + image);});
deckImages.forEach((image) => {imagesData.push('decks/' + image);});
mazeImages.forEach((image) => {imagesData.push('mazes/' + image);});
portalImages.forEach((image) => {imagesData.push('portal/' + image);});
progressImages.forEach((image) => {imagesData.push('progress/' + image);});
surveyImages.forEach((image) => {imagesData.push('surveys/' + image);});

export {
	imagesData
};

import React from 'react';
import PropTypes from 'prop-types';
import {getCurrentDate} from 'helpers/date-helper';
import Survey from './survey';

class SurveyController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			stepIndex: 0,
			surveyData: null
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Get survey data */
		let surveyData = JSON.parse(JSON.stringify(this.props.surveysData)).find((survey) => {
			return survey.id === this.props.surveyId;
		});

		/* Update state */
		this.setState({isLoading: false, surveyData});
	}

	/**
	 * Update input 
	 */
	handleUpdateInput = (event) => {
		let name = event.target.name;
		let value = event.target.value;

		let surveyData = JSON.parse(JSON.stringify(this.state.surveyData));
		if (surveyData.steps[this.state.stepIndex].hasOwnProperty(name)) {
			surveyData.steps[this.state.stepIndex][name] = value;
			this.setState({surveyData});
		}
	}

	/**
	 * Select / deselect option
	 * @param {number} optionId 
	 */
	toggleOption = (optionId) => {
		let surveyData = JSON.parse(JSON.stringify(this.state.surveyData));
		surveyData.steps[this.state.stepIndex].options.forEach((option) => {
			if (option.id === optionId) {
				option.selected = !option.selected;
			} else {
				if (surveyData.steps[this.state.stepIndex].type === 'tag-choose') {
					/* Only one option can be selected for this type */
					option.selected = false;
				}
			}
		});
		this.setState({surveyData});
	}

	/**
	 * Confirm current step
	 */
	handleConfirmStep = () => {
		let stepIndex = this.state.stepIndex;
		const numberOfSteps = this.state.surveyData.steps.length;

		/* Special cases for tag promt 1 */
		if (
			this.state.surveyData.surveyType.slice(0, 4) === 'tag-' &&
			this.state.surveyData.steps[stepIndex].type === 'tag-choose'
		) {
			/* Type tag promt 1, triggered by specific card, player can choose to put specific tag in detention */
			const selectedOption = this.state.surveyData.steps[stepIndex].options.find((o) => {
				return o.selected === true;
			});
			if (selectedOption && selectedOption.id === 'yes') {
				/* Start tag detention and complete survey */
				this.handleSaveSurvey(true);
			} else {
			/* Do not start tag detention, end survey */
				this.handleSaveSurvey();
			}
			return;
		}

		/* Special cases for tag promt 2 */
		if (this.state.surveyData.surveyType.slice(0, 5) === 'tag2-') {
			/* Type tag promt 2, triggered by game end */
			if (this.state.surveyData.steps[stepIndex].type === 'tag-choose') {
				/* Player decided wether to renew tag detention */
				const selectedOption = this.state.surveyData.steps[stepIndex].options.find((o) => {
					return o.selected === true;
				});
				
				if (!selectedOption || selectedOption.id === 'no') {
					/* Player chose to renew tag detention  */
					if (stepIndex + 1 >= numberOfSteps) {
						/* No more steps, renew tag detention and end survey */
						this.handleSaveSurvey(true);
					} else {
						/* Go to next step */
						this.setState({stepIndex: stepIndex + 1});
					}
				}  else {
					/* Player chose to remove tag detention */
					this.handleSaveSurvey();
				}
			} else if (
				this.state.surveyData.steps[stepIndex].type === 'tag-feedback' ||
				this.state.surveyData.steps[stepIndex].type === 'tag-mc'
			) {
				/* Feedback about why player wanted to renew tag detention */
				if (stepIndex + 1 < numberOfSteps) {
					/* Go to next step */
					this.setState({stepIndex: stepIndex + 1});
				} else {
					/* Save and finish survey */
					this.handleSaveSurvey(true);
				}
			}


			return;
		}

		/* Should not happen! */
		if (
			this.state.surveyData.surveyType.slice(0, 4) !== 'tag-' &&
			this.state.surveyData.surveyType.slice(0, 5) !== 'tag2-' &&
			(
				this.state.surveyData.steps[stepIndex].type === 'tag-choose' ||
				this.state.surveyData.steps[stepIndex].type === 'tag-feedback' ||
				this.state.surveyData.steps[stepIndex].type === 'tag-mc'
			)
		) {
			console.log('Warning! This should not happen!');
			console.log(
				'Steps of type "tag-choose"/"tag-feedback"/"tag-mc" should only occur in surveys of type tag prompt 1/2'
			);
			this.handleSaveSurvey();
			return;
		}
		if (
			(
				this.state.surveyData.surveyType.slice(0, 4) === 'tag-' ||
				this.state.surveyData.surveyType.slice(0, 5) === 'tag2-' 
			) &&
			this.state.surveyData.steps[stepIndex].type !== 'tag-choose' &&
			this.state.surveyData.steps[stepIndex].type !== 'tag-feedback' && 
			this.state.surveyData.steps[stepIndex].type !== 'tag-mc'
		) {
			console.log('Warning! This should not happen!');
			console.log(
				'Steps not of type "tag-choose"/"tag-feedback" can not occur in surveys of type tag prompt 1/2'
			);
			this.handleSaveSurvey();
			return;
		}



		/* Normal survey */
		if (stepIndex + 1 < numberOfSteps) {
			/* Go to next step */
			this.setState({stepIndex: stepIndex + 1});
		} else {
			/* Save and finish survey */
			this.handleSaveSurvey();
		}
	};

	/**
	 * Save survey and continue game
	 * @param {string} tagId 
	 */
	handleSaveSurvey = (startOrRenewTagDetention = false) => {
		let surveyData = null;
		if (this.state.surveyData) {
			let surveyDate = getCurrentDate();
			surveyData = {
				id: this.props.surveyId, 
				type: this.state.surveyData.surveyType,
				surveyDate, 
				inputs: Array.apply(null, Array(this.state.surveyData.steps.length)).map(() => {return null;})
			};

			this.state.surveyData.steps.forEach((step, index) => {
				if (
					step.type === 'textarea' || 
					step.type === 'slider' || 
					step.type === 'tag-feedback'
				) surveyData.inputs[index] = step.input;
				if (
					(step.type === 'multiple-choice' || step.type === 'tag-mc') && 
					step.options && 
					step.options.length > 0
				) {
					let input = '';
					step.options.forEach((option) => {
						if (option.selected === true) input += ' ' + option.title + ',';
					});
					if (step.input && step.input.length > 0) input += ' ' + step.input;
					surveyData.inputs[index] = input;
				}
				if (step.type === 'tag-choose' && step.options && step.options.length > 0) {
					let input = '';
					step.options.forEach((option) => {if (option.selected === true) input = option.title;});
					surveyData.inputs[index] = input;
				}
			});
		}

		this.props.handleCompleteSurvey(surveyData, startOrRenewTagDetention);
	}

	/**
	 * Render component
	 */
	render() {
		return (
			<Survey 
				stepIndex={this.state.stepIndex}
				surveyId={this.props.surveyId}
				surveyData={this.state.surveyData} 
				goToStep={this.goToStep}
				handleUpdateInput={this.handleUpdateInput}
				toggleOption={this.toggleOption}
				handleConfirmStep={this.handleConfirmStep}
				handleSaveSurvey={this.handleSaveSurvey}
			/>
		);
	}
}

SurveyController.propTypes = {
	surveyId: PropTypes.string.isRequired,
	surveysData: PropTypes.array.isRequired,
	handleCompleteSurvey: PropTypes.func.isRequired
};

export default SurveyController;
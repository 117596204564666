import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './select.scss';

class Select extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownIsOpen: false
		};
		this.toggleDropdown = this.toggleDropdown.bind(this);
	};

	toggleDropdown() {
		let dropdownIsOpen = !this.state.dropdownIsOpen;
		this.setState({dropdownIsOpen: dropdownIsOpen});
	}

	handleSelect(optionValue) {
		this.props.onSelect(
			{target: {name: this.props.type, value: optionValue}}, ...this.props.extraParams);
		this.toggleDropdown();
	}

	render() {
		let options = this.props.options;
		let selectedId = this.props.selectedId;
		let selectedName = this.props.defaultValue;
		if (options.some((option) => {
			if (option.hasOwnProperty('id')) return option.id === selectedId;
			return option === selectedId;
		})) {
			let selectedOption = options.filter((option) => {
				if (option.hasOwnProperty('id')) return option.id === selectedId;
				return option === selectedId;
			})[0];
			if (selectedOption.hasOwnProperty('title')) {
				selectedName = selectedOption.title;
			} else if (selectedOption.hasOwnProperty('id')) {
				selectedName = selectedOption.id;
			} else {
				if (typeof selectedOption === 'string') selectedName = selectedOption;
			}
		}
		if ((this.props.type === 'cardId' || this.props.type === 'locksCards') && selectedName.length > 0) {
			selectedName = selectedName + ' (' + selectedId + ')';
		}

		let className = 'Select';
		if (this.state.dropdownIsOpen) {className += ' open';}
		if (this.props.class) {className += ' ' + this.props.class;}
		
		return (
			<div className={className}>
				<div className={'Select-panel Select-panel--' + selectedId} onClick={()=>{this.toggleDropdown();}}>
					<div className="Select-selected">{selectedName}</div>
					<div className="Select-button" />
				</div>
				<div className="Select-dropdown">
					{options.map((option, index) => {
						let className = 'Select-option';
						let optionId = (option.hasOwnProperty('id') ? option.id : option);
						let optionTitle = (option.hasOwnProperty('title') 
							? option.title 
							: (option.hasOwnProperty('id') 
								? option.id
								: (typeof option === 'string' 
									? option
									: '?')
							)
						);
						let optionValue = (option.hasOwnProperty('value') 
							? option.value 
							:  (option.hasOwnProperty('id') ? option.id : option)
						);
						if (selectedId === optionId) {className += ' Select-option--selected';}
						if (this.props.type === 'cardId' || this.props.type === 'locksCards') {
							optionTitle = optionTitle + ' (' + optionId + ')';
						}
						return (
							<div key={index} className={className} onClick={()=>{this.handleSelect(optionValue);}}>
								{optionTitle}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

Select.defaultProps = {
	extraParams: []
};

Select.propTypes = {
	type: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	defaultValue: PropTypes.string.isRequired,
	selectedId: PropTypes.any,
	extraParams: PropTypes.array,
	onSelect: PropTypes.func.isRequired,
	class: PropTypes.string
};

export default Select;

import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './info.scss';

const Info = ({closePopup}) => {
	return (
		<div className="Info">
			<div className="Info-text">{renderMarkdown(generalUiTexts.infoPopup.text)}</div>
			<div className="Info-logo tryg" />
			<div className="Info-logo region" />
			<div className="Info-logo cgl" />
			<div className="Info-closeBtn" onClick={closePopup}>{generalUiTexts.infoPopup.buttonText}</div>
		</div>				
	);
};

Info.propTypes = {
	closePopup: PropTypes.func.isRequired
};

export default Info;
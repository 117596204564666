import React from 'react';
import PropTypes from 'prop-types';
import TagPopup from './tag-popup';
import './tags.scss';

const Tags = (props) => {
	let {
		isEditing, 
		isSaving,
		isCreating,
		tags,
		cards,
		selectedTag,
		selectTag,
		updateTag, 
		saveTag,
		createNewTag,
		deleteTag,
		goToPage,
	} = props;

	return (
		<div className="Tags">
			<div className="Tags-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>

			{/* Tags */}
			<div className="Tags-section">
				<div className="Tags-title">Tags:</div>
				<div className="Tags-header">
					<div className="Tags-tagId">Id</div>
					<div className="Tags-tagTitle">Titel</div>
					<div className="Tags-tagCards">Kort</div>
				</div>
				{tags.map((tag, index) => {
					let cardsWithTag = '';
					cards.forEach((card) => {
						if (card.hasOwnProperty('tags') && card.tags.indexOf(tag.id) >= 0) {
							if (cardsWithTag.length === 0) {
								cardsWithTag = card.title;
							} else {
								cardsWithTag += ', ' + card.title;
							}
						}
					});
					return (
						<div key={index} className="Tags-row" onClick={() => {selectTag(tag.id);}} >
							<div className="Tags-tagId">{tag.id}</div>
							<div className="Tags-tagTitle">{tag.title}</div>
							<div className="Tags-tagCards">{cardsWithTag}</div>
						</div>
					);
				})}
				<div 
					className={'Tags-newTagBtn' + (isCreating ? ' Tags-newTagBtn--saving' : '') } 
					onClick={() => {createNewTag();}}
				>Opret nyt tag</div>
			</div>

			{/* Edit tag popup */}
			{selectedTag &&
				<TagPopup 
					isEditing={isEditing}
					isSaving={isSaving}
					selectedTag={selectedTag} 
					tags={tags}
					updateTag={updateTag} 
					selectTag={selectTag}
					saveTag={saveTag}
					deleteTag={deleteTag}
				/>
			}

		</div>
	);
};

Tags.defaultProps = {
	selectedTag: null
};

Tags.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	tags: PropTypes.array.isRequired,
	cards: PropTypes.array.isRequired,
	selectedTag: PropTypes.object,
	selectTag: PropTypes.func.isRequired,
	updateTag: PropTypes.func.isRequired,
	saveTag: PropTypes.func.isRequired,
	createNewTag: PropTypes.func.isRequired,
	deleteTag: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Tags;
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getPlayerData} from 'helpers/player-data-helper';
import Splash from 'components/splash/splash';
import Game from 'components/game/game';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'splash',
			prevPage: null,
			playerData: getPlayerData()
		};
		this.timeout = null;
	}

	/**
	 * Go to game
	 */
	handleGoToGame = () => {
		this.setState({page: 'game', prevPage: 'splash'}, () => {
			this.timeut = setTimeout(() => {this.setState({prevPage: null});}, 1000);
		});
	}

	/**
	 * Update player data
	 * @param {object} updates 
	 */
	updatePlayerData = (updates) => {
		const newPlayerData = Object.assign({}, this.state.playerData, updates);
		return new Promise((resolve)=>{
			this.setState({playerData: newPlayerData}, () => {
				resolve({status: 'ok'});
			});
		});
	}

	/**
	 * Render 
	 * @returns 
	 */
	render = () => {
		return (
			<React.Fragment>
				{(this.state.page === 'splash' || this.state.prevPage === 'splash') &&
				<Splash 
					isLoading={this.props.isLoading}
					splashPageClass={(this.state.page === 'game' ? 'slideOut' : 'active')} 
					handleGoToGame={this.handleGoToGame}
				/>}
				{this.state.page === 'game' && <Game 
					playerData={this.state.playerData}
					areasData={this.props.areasData}
					decksData={this.props.decksData}
					missionsData={this.props.missionsData}
					surveysData={this.props.surveysData}
					cardImages={this.props.cardImages}
					updatePlayerData={this.updatePlayerData}
				/>}
			</React.Fragment>
		);
	}
}

LoginController.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	cardImages: PropTypes.array.isRequired
};

export default LoginController;
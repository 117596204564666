import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import {getCurrentDate} from 'helpers/date-helper';
import {
	getTimePlayed, 
	getAvailableCardsInDeck, 
	pickRandomCard,
	checkIfMissionConditionsAreMet, 
	getIsEndOfDream
} from 'helpers/game-helper';
import ProgressController from 'components/progress/progress-controller';
import MazeGame from './maze-game';
import PortalController from 'components/portal/portal-controller';
import SurveyController from 'components/survey/survey-controller';
import Overview from 'components/overview/overview';
import MainMenu from 'components/main-menu/main-menu';
import TheEnd from 'components/the-end/the-end';

class MazeGameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showMainMenu: false,
			showProgress: false,
			showFeedback: false,
			showMissionCompletedFeedback: false,
			showPortal: false,			
			portalIsOpen: false,
			showSurvey: false,
			showEndOfDreamCard: false,
			isGameover: false,
			isFreeplay: false,
			isEndOfDream: false,
			showTheEndPopup: false,			
			areaData: null,
			deckId: null,
			cardId: null,
			cardDetention: [],
			selectedOption: null,
			missionId: null,
			surveyId: null,
			startTimeSecs: null,
			pauseTimeSecs: null,
			timeLogStamp: Date.now(),
			timeLogged: 0
		};
		this.timeout1 = null;
		this.timeout2 = null;
		this.timeout3 = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		this.startNewGame();
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeouts */
		if (this.timeout1) clearTimeout(this.timeout1);
		if (this.timeout2) clearTimeout(this.timeout2);
		if (this.timeout3) clearTimeout(this.timeout3);
	}

	/**
	 * Start new game
	 */
	startNewGame = () => {
		/* Card and tag detention */
		let cardDetention = [];
		JSON.parse(JSON.stringify(this.props.playerData.cardDetention)).forEach((card) => {
			card.playCount += 1;
			if (card.playCount < appConfig.cardDetentionDuration) cardDetention.push(card);
		});
		let tagDetention = JSON.parse(JSON.stringify(this.props.playerData.tagDetention));
		for (let prop in tagDetention) {
			if (tagDetention.hasOwnProperty(prop)) {
				if (!tagDetention[prop].playCount) tagDetention[prop].playCount = 0;
				tagDetention[prop].playCount += 1;
			}
		}
		this.props.updatePlayerData({cardDetention: cardDetention, tagDetention: tagDetention});

		this.setState({cardDetention}, () => {
			/* Get area data */
			let areaData = this.props.areasData.find((maze) => {return maze.id === this.props.playerData.mazeId;});
				
			/* Show onboarding deck or draw random card from morning deck */
			let deckId = 'onboarding';
			let cardId = 'onboarding-1';
			if (this.props.playerData.onboardingPlayed) {
				deckId = 'morning';
				if (this.state.portalIsOpen) deckId = 'morning2';
				cardId = this.handlePickRandomCard(deckId); 
			}

			/* Game start time */
			const startTimeSecs = Math.floor(new Date().getTime() / 1000);

			/* Check if freeplay (player has completed game) */
			const isFreeplay = (this.props.playerData.gameEnded ? true : false);

			/* Update state */
			this.setState({
				/* Reset timer */
				showProgress: false
			}, () => {
				/* Start new game */
				this.setState({
					isFreeplay,
					startTimeSecs, 
					pauseTimeSecs: startTimeSecs, 
					deckId, 
					cardId, 
					areaData, 
					showProgress: true,
					selectedOption: null,
					showFeedback: false,
					missionId: null,
					showMissionCompletedFeedback: false,
					surveyId: null,
					showSurvey: false,
					isGameover: false,
					isEndOfDream: false,
					showEndOfDreamCard: false,
					showPortal: false,
					showOverview: false,
					portalIsOpen: false,
					showMainMenu: false,
					animateMissionCompleted: false,
					showTheEndPopup: false,
					timeLogStamp: Date.now(),
					timeLogged: 0
				});
			});
		});
	}

	/**
	 * Hide / show main menu popup
	 */
	handleToggleMainMenu = () => {
		this.setState({showMainMenu: !this.state.showMainMenu});
	}
	
	/**
	 * Player selects card option (left / right OR button)
	 * @param {object} optionData 
	 */
	handleSelectOption = (optionData) => {
		/* Check if dream has ended */
		const isEndOfDream = (optionData.hasOwnProperty('nextCard')
			? getIsEndOfDream(
				getTimePlayed(this.state.startTimeSecs), 
				optionData, 
				this.state.deckId, 
				getAvailableCardsInDeck(
					this.state.isFreeplay,
					this.props.decksData,
					optionData.nextCard.deckId, 
					this.props.playerData.mazeId,
					this.props.playerData.lockedCards,
					this.state.cardDetention,
					this.props.playerData.tagDetention
				)
			)
			: false
		);

		/* Check if a mission was completed */
		const missionId = (
			optionData.mission && 
			checkIfMissionConditionsAreMet(optionData.mission, this.props.playerData)
				? optionData.mission.missionId : null
		);


		/* Check if a survey was triggered */
		let surveyId = null;
		if (optionData.survey && optionData.survey.hasOwnProperty('isRandomSurvey')) {
			if (optionData.survey.isRandomSurvey === true) {
				/* Random survey of type triggered */
				surveyId = this.pickRandomSurvey(optionData.survey.surveyType);
			} else if (optionData.survey.surveyId) {
				/* Specific survey triggered */
				if (optionData.survey.surveyId) surveyId = optionData.survey.surveyId;
			}
		}

		/* Log time spent */
		let updateLogTime = 0;
		let timeLogStamp = Date.now();
		const miliseconds = Math.min(timeLogStamp - this.state.timeLogStamp, appConfig.inactivityLimitSeconds * 1000);
		let timeLogged = this.state.timeLogged + miliseconds;
		if (isEndOfDream || timeLogged > appConfig.inactivityLimitSeconds * 1000) {
			updateLogTime = timeLogged;
			timeLogged = 0;
		}

		/* Update player data */
		this.handleUpdatePlayerData(optionData, missionId, updateLogTime).then((response) => {
			/* Update state */
			this.setState({
				isEndOfDream: isEndOfDream,
				selectedOption: optionData,
				missionId: missionId,
				surveyId: surveyId,
				timeLogStamp,
				timeLogged
			}, () => {
				/* Go to next game step */
				this.goToNextGameStep();
			});
		});
	}

	/**
	 * Update player data based on choice
	 * @param {object} optionData 
	 * @param {number} missionId
	 */
	handleUpdatePlayerData = (optionData, missionId, updateLogTime) => {
		return new Promise((resolve)=>{
			/* Prepare player updates */
			let playerDataUpdates = null;

			/* Update "number of games played" if just starting a game */
			if (this.state.deckId === 'morning') {
				let playDate = getCurrentDate();
				let gamesPlayed = JSON.parse(JSON.stringify(this.props.playerData.gamesPlayed));
				gamesPlayed.push(playDate);
				if (!playerDataUpdates) playerDataUpdates = {};
				playerDataUpdates.gamesPlayed = gamesPlayed;
				// if (!this.props.playerData.gameStarted) {
				// 	playerDataUpdates.gameStarted = playDate;
				// }
			}

			/* Update discovered secrets */
			if (optionData.secretId) {
				if (this.props.playerData.secretsDiscovered.indexOf(optionData.secretId) < 0) {
					let secretsDiscovered = JSON.parse(JSON.stringify(this.props.playerData.secretsDiscovered));
					secretsDiscovered.push(optionData.secretId);
					if (!playerDataUpdates) playerDataUpdates = {};
					playerDataUpdates.secretsDiscovered = secretsDiscovered;
				}
			}

			/* Update completed missions */
			if (missionId) {
				let missionsCompleted = JSON.parse(JSON.stringify(this.props.playerData.missionsCompleted));
				missionsCompleted.push({
					id: missionId, 
					mazeId: this.props.playerData.mazeId,
					completed: dayjs(new Date()).format('YYYY-MM-DD')
				});
				if (!playerDataUpdates) playerDataUpdates = {};
				playerDataUpdates.missionsCompleted = missionsCompleted;

				/* Lock cards */
				if (!this.state.isFreeplay) {
					if (
						optionData.mission && optionData.mission.locksCards && 
						optionData.mission.locksCards.length > 0
					) {
						let lockedCards = JSON.parse(JSON.stringify(this.props.playerData.lockedCards));
						optionData.mission.locksCards.forEach((card) => {
							if (lockedCards.indexOf(card) < 0) lockedCards.push(card);
							playerDataUpdates.lockedCards = lockedCards;
						});
					}
				}
			}

			/* Update time logged */
			if (updateLogTime) {
				let playerTimeLog = this.props.playerData.timeLog ? {...this.props.playerData.timeLog} : {};
				const currentDate = getCurrentDate();
				if (!playerTimeLog.hasOwnProperty(currentDate)) playerTimeLog[currentDate] = 0;
				playerTimeLog[currentDate] = playerTimeLog[currentDate] + updateLogTime;
				if (!playerDataUpdates) playerDataUpdates = {};
				playerDataUpdates.timeLog = playerTimeLog;
			}

			/* Update player data */
			if (playerDataUpdates) this.props.updatePlayerData(playerDataUpdates);
			resolve({status: 'ok'});
		});	
	}

	/**
	 * Update card detention
	 * @param {array} newCardDetention 
	 */
	handleUpdateCardDetention = (newCardDetention) => {
		this.props.updatePlayerData({cardDetention: newCardDetention});
		this.setState({cardDetention: newCardDetention});
	}

	/**
	 * Go to next game step
	 */
	goToNextGameStep = () => {
		/* Gameover */
		if (this.state.isGameover) {
			console.log('Warning: "Next step: is game over" triggered');
			this.setState({showMainMenu: true});
			return;
		}

		/* Show feedback */
		if (this.state.selectedOption && this.state.selectedOption.feedback && !this.state.showFeedback) {
			if (this.timeout1) clearTimeout(this.timeout1);
			this.timeout1 = setTimeout(() => {this.setState({showFeedback: true});}, 500);
			return;
		}
		
		/* Show mission completed feedback */
		if (this.state.missionId !== null && !this.state.showMissionCompletedFeedback) {
			if (this.timeout1) clearTimeout(this.timeout1);
			this.timeout1 = setTimeout(() => {
				let cardId = this.state.cardId;
				this.setState({cardId: null}, () => {
					this.setState({cardId: cardId, showMissionCompletedFeedback: true});
				});
			}, 500);
			return;
		}
		
		/* Show mission completed flow (maze -> portal) */
		if (
			!this.state.isFreeplay && 
			!this.state.showEndOfDreamCard && 
			this.state.missionId !== null && this.state.showMissionCompletedFeedback === true && 
			this.state.areaData.portal && this.state.showPortal === false
		) {
			if (this.timeout1) clearTimeout(this.timeout1);
			if (this.timeout2) clearTimeout(this.timeout2);
			if (this.timeout3) clearTimeout(this.timeout3);
			this.timeout1 = setTimeout(() => {
				this.setState({showOverview: true, animateMissionCompleted: true});
			}, 500);
			this.timeout2 = setTimeout(() => {
				this.setState({showPortal: true});
			}, 6000);
			this.timeout3 = setTimeout(() => {
				this.setState({showOverview: false, animateMissionCompleted: false});
			}, 7000);
			return;
		}

		/* Portal unlocked */
		if (this.state.portalIsOpen) {
			if (this.props.playerData.gameEnded) {
				/* Finish maze */
				this.setState({isGameover: true, surveyId: 'exit', showSurvey: true});
			} else {
				/* Go to next area, end game */
				this.setState({isGameover: true, showMainMenu: true});
			}
			return;
		}

		/* End of dream */
		if (this.state.isEndOfDream) {
			if (this.timeout1) clearTimeout(this.timeout1);

			if (!this.state.showEndOfDreamCard) {
				/* Show gameover card */
				this.timeout1 = setTimeout(() => {
					this.setState({cardId: null}, () => {
						this.setState({
							deckId: 'night',
							cardId: 'night-1', 
							showEndOfDreamCard: true,
							showPortal: false,
						});
					});
				}, 500);
				return;
			}

			/* Show gameover survey (specific survey, tag survey or random survey) */
			const tagDetention = this.props.playerData.tagDetention;
			let activatedTag = null;
			for (let prop in tagDetention) {
				if (activatedTag !== null) break;
				if (tagDetention.hasOwnProperty(prop)) {
					let tagDetentionDuration = appConfig.tagDetentionDuration;
					if (tagDetention[prop].count > 1) tagDetentionDuration += appConfig.tagDetentionAddon;
					if (tagDetention[prop].playCount >= tagDetentionDuration) activatedTag = prop;
				}
			}
			const surveyId = (this.state.surveyId === null 
				? activatedTag ? this.pickRandomSurvey('tag2-' + activatedTag) : this.pickRandomSurvey('gameover') 
				: this.state.surveyId
			);
			this.timeout1 = setTimeout(() => {this.setState({showSurvey: true, surveyId});}, 500);
			return;
		}

		/* Show survey related to selected option */
		if (this.state.surveyId !== null && !this.state.showSurvey) {
			if (this.timeout1) clearTimeout(this.timeout1);
			this.timeout1 = setTimeout(() => {this.setState({showSurvey: true});}, 500);
			return;
		}

		/* Show next card */
		let deckId = this.state.selectedOption.nextCard.deckId;
		let nextCardType = this.state.selectedOption.nextCard.type;

		/* Mark onboarding as completed */
		if (deckId !== 'onboarding' && !this.props.playerData.onboardingPlayed) {
			this.props.updatePlayerData({onboardingPlayed: true, gameStarted: getCurrentDate()});
		}

		/* Check if it is time for a pause card */
		let pauseTimeSecs = this.state.pauseTimeSecs;
		if (deckId === 'basic' && nextCardType === 'random') {
			let timeSinceLastPause = getTimePlayed(pauseTimeSecs);
			let timeLeft = appConfig.gameDurationLimitSecs - 
				getTimePlayed(this.state.startTimeSecs);
			if (timeSinceLastPause > appConfig.pauseIntervalSecs && timeLeft > 60) {
				deckId = 'pause';
				nextCardType = 'random';
				pauseTimeSecs = Math.floor(new Date().getTime() / 1000);
			}
		} 

		/* Get card id */
		let cardId = (nextCardType === 'random' 
			? this.handlePickRandomCard(deckId) 
			: this.state.selectedOption.nextCard.cardId
		);

		/* Update state */
		let updateStateData = {
			pauseTimeSecs,
			cardId, 
			deckId, 
			selectedOption: null, 
			missionId: null,
			surveyId: null,
			showFeedback: false,
			showMissionCompletedFeedback: false,
			showSurvey: false,
			showPortal: false,
			showMainMenu: false,
			showOverview: false,
			animateMissionCompleted: false,
		};
		if (this.timeout1) clearTimeout(this.timeout1);
		this.timeout1 = setTimeout(() => {
			this.setState({cardId: null}, () => {
				this.setState(updateStateData);
			});
		}, 500);
	}

	/**
	 * Pick a random card from the deck
	 * @param {string} deckId 
	 */
	handlePickRandomCard = (deckId) => {	
		// if (
		// 	deckId === 'basic' && 
		// 	this.state.cardId !== 'basic-98' && 
		// 	this.state.cardId !== 'basic-99' &&
		// 	this.state.cardId !== 'basic-100' 
		// ) return 'basic-98';

		const cardId = pickRandomCard(
			deckId, 
			this.state.cardDetention,
			this.props.playerData, 
			this.state.isFreeplay, 
			this.props.decksData, 
			this.handleUpdateCardDetention
		);	
		
		return cardId;
	}

	/**
	 * Close portal popup
	 * @param {bool} portalIsOpen 
	 */
	handleClosePortalPopup = (portalIsOpen) => {
		this.setState({portalIsOpen}, () => {
			this.goToNextGameStep();
		});
	}

	/**
	 * Pick a random survey of a specific type
	 * @param {string} surveyType 
	 */
	pickRandomSurvey = (surveyType) => {
		let availableSurveys = this.props.surveysData.filter((survey) => {
			return (
				survey.surveyType === surveyType && 
				(surveyType !== 'gameover' || survey.mazeId <= this.props.playerData.mazeId)
			);
		});
		if (availableSurveys.length > 0) {
			let randomIndex = Math.floor(Math.random() * availableSurveys.length);
			let surveyId = availableSurveys[randomIndex].id;
			return surveyId;
		} 
		return null;
	}

	/**
	 * Close survey popup
	 */
	handleCompleteSurvey = (surveyData, startOrRenewTagDetention) => {
		if (surveyData) {
			/* Log time spent */
			let updateLogTime = 0;
			let timeLogStamp = Date.now();
			const miliseconds = 
				Math.min(timeLogStamp - this.state.timeLogStamp, appConfig.inactivityLimitSeconds * 1000);
			let timeLogged = this.state.timeLogged + miliseconds;
			updateLogTime = timeLogged;
			timeLogged = 0;
			let playerTimeLog = this.props.playerData.timeLog ? {...this.props.playerData.timeLog} : {};
			const currentDate = getCurrentDate();
			if (!playerTimeLog.hasOwnProperty(currentDate)) playerTimeLog[currentDate] = 0;
			playerTimeLog[currentDate] = playerTimeLog[currentDate] + updateLogTime;
			

			/* Add survey response to player data */
			let surveys = (this.props.playerData.hasOwnProperty('surveys') 
				? JSON.parse(JSON.stringify(this.props.playerData.surveys)) 
				: []
			);
			surveyData.mazeId = this.props.playerData.mazeId;
			surveys.push(surveyData);

			/* Tag detention - promt 1 */
			let tagDetention = JSON.parse(JSON.stringify(this.props.playerData.tagDetention));
			if (surveyData && surveyData.type.slice(0, 4) === 'tag-' && startOrRenewTagDetention) {
				const tagId = surveyData.type.slice(4, surveyData.type.length);
				tagDetention[tagId] = {playCount: 0, count: 1};
			}
			

			/* Tag detention - promt 2 */
			if (surveyData && surveyData.type.slice(0, 5) === 'tag2-') {
				const tagId = surveyData.type.slice(5, surveyData.type.length);
				if (startOrRenewTagDetention) {
					/* Renew tag detention */
					if (!tagDetention[tagId]) {
						tagDetention[tagId] = {playCount: 0, count: 1};
					} else {
						tagDetention[tagId].playCount = 0;
						tagDetention[tagId].count = tagDetention[tagId].count + 1;
					}
				} else {
					/* Remove tag detention */
					if (tagDetention[tagId]) delete tagDetention[tagId];
				}
			}

			/* Update player data */
			this.props.updatePlayerData({surveys: surveys, timeLog: playerTimeLog, tagDetention});	

			/* Finish game */
			if (this.props.playerData.gameEnded && surveyData.id === 'exit') {
				this.setState({isEndOfDream: true, isGameover: true, showTheEndPopup: true, timeLogStamp, timeLogged});
			} else {
				/* End game or go to next game step */
				if (this.state.isEndOfDream) {
					this.setState({showMainMenu: true, isGameover: true, timeLogStamp, timeLogged});
				} else {
					this.setState({timeLogStamp, timeLogged}, () => {this.goToNextGameStep();});
				}			
			}
		} else {
			/* Unknown survey, go to next step */
			this.goToNextGameStep();
		}
	}

	/**
	 * Close the end popup, finish game
	 */
	handleCloseTheEndPopup = () => {
		this.setState({showTheEndPopup: false, showMainMenu: true});
	}

	/**
	 * Render component
	 */
	render() {
		const decksData = this.props.decksData;

		let deckData = null, cardData = null;
		if (decksData.some((deck) => {return deck.id === this.state.deckId;})) {
			deckData = decksData.filter((deck) => {return deck.id === this.state.deckId;})[0];
			if (deckData.cards.some((card) => {return card.id === this.state.cardId;})) {
				cardData = deckData.cards.filter((card) => {return card.id === this.state.cardId;})[0];
			}
		}

		let completedMission = null;
		if (this.state.showMissionCompletedFeedback && 
			this.props.missionsData.some((mission) => {return mission.id === this.state.missionId;})
		) {
			completedMission = this.props.missionsData.find((mission) => {return mission.id === this.state.missionId;});
		}

		return (
			<React.Fragment>
				<MazeGame
					showEndOfDreamCard={this.state.showEndOfDreamCard}
					playerData={this.props.playerData}
					cardData={cardData}
					selectedOption={this.state.selectedOption}
					showFeedback={this.state.showFeedback}
					missionData={completedMission} 
					showMissionCompletedFeedback={this.state.showMissionCompletedFeedback}
					handleSelectOption={this.handleSelectOption}
					goToNextGameStep={this.goToNextGameStep}
					handleToggleMainMenu={this.handleToggleMainMenu}
				/>
				{this.state.showProgress && <ProgressController 
					hideProgress={false} 
					isFreeplay={this.state.isFreeplay}
					showPortal={this.state.showPortal}
					areasData={this.props.areasData}
					playerData={this.props.playerData}
				/>}

				{this.state.showOverview &&
					<Overview 
						animateMissionCompleted={this.state.animateMissionCompleted}
						animatePortalIsOpen={this.state.portalIsOpen}
						areasData={this.props.areasData}
						playerData={this.props.playerData}
						missionId={this.state.missionId}
						missionsData={this.props.missionsData}
					/>
				}
				{this.state.showPortal && 
					<PortalController 
						areasData={this.props.areasData}
						playerData={this.props.playerData}
						updatePlayerData={this.props.updatePlayerData}
						handleClosePortalPopup={this.handleClosePortalPopup}
					/>
				}
				{(this.state.surveyId && this.state.showSurvey) && 
					<SurveyController 
						surveyId={this.state.surveyId} 
						surveysData={this.props.surveysData}
						handleCompleteSurvey={this.handleCompleteSurvey}
					/>
				}

				{this.state.showTheEndPopup && 
					<TheEnd handleCloseTheEndPopup={this.handleCloseTheEndPopup} />
				}

				{this.state.showMainMenu &&
					<MainMenu
						animateSlideIn={false}
						isLoading={false}
						isPlaying={true}
						isGameover={this.state.isGameover}
						playerData={this.props.playerData}
						decksData={this.props.decksData}
						missionsData={this.props.missionsData}
						handleContinueGame={(this.state.isGameover ? this.startNewGame : this.handleToggleMainMenu)}
					/>
				}
			</React.Fragment>
		);
	}
}

MazeGameController.propTypes = {
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired
};

export default MazeGameController;
import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import appConfig from 'config/app.config';
import {statsUiTexts} from 'components/admin/ui-texts';
import {getUsersSheet, getSurveysOverviewSheet, getSurveySheet} from 'helpers/stats-helper';
import './stats.scss';

const Stats = (props) => {
	const {
		isLoading,
		isDownloading,
		authSessionData,
		usersData,
		surveysData,
		goToPage
	} = props;

	if (isLoading) {
		return (
			<div className="Stats">
				<div className="Stats-backBtn" onClick={() => {goToPage('admin');}}>{statsUiTexts.back}</div>
				<div className="Stats-title">{statsUiTexts.stats}</div>
				<div className="Stats-loading">Loading ...</div>
			</div>
		);
	}

	/* Get data sheets */
	const dataSheets = [];
	const usersSheet = getUsersSheet(usersData);
	dataSheets.push(usersSheet);
	const surveysOverviewSheet = getSurveysOverviewSheet(surveysData);
	dataSheets.push(surveysOverviewSheet);
	surveysData.forEach((survey) => {
		const surveySheet = getSurveySheet(survey, usersData);
		dataSheets.push(surveySheet);
	});
	
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	return (
		<div className="Stats">
			<div className="Stats-backBtn" onClick={() => {goToPage('admin');}}>{statsUiTexts.back}</div>
			<div className="Stats-title">{statsUiTexts.stats}</div>
			<div className="Stats-players"><span>{statsUiTexts.numberOfPlayers}:</span> {usersData.length}</div>
		
			{
				((appConfig.env !== 'production' && appConfig.env !== 'en') ||
				authSessionData.email === 'mguala@health.sdu.dk' ||
				authSessionData.email === 'hnnielsen@health.sdu.dk') && 
			<ExcelFile 
				element={
					<button className={'Stats-downloadBtn' + (isDownloading ? ' loading' : '')}>
						{statsUiTexts.download}
					</button>
				}
			>
				{dataSheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>}

		</div>
	);
};

Stats.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isDownloading: PropTypes.bool.isRequired,
	authSessionData: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Stats;
import React from 'react';
import PropTypes from 'prop-types';
import './images.scss';

const Images = (props) => {
	let {
		isLoading,
		isUploading,
		images,
		imagesToUpload,
		errorMsg,
		deleteErrorMsg,
		selectImagesToUpload,
		uploadImages,
		handleDeleteImage,
		closeErrorPopup,
		goToPage
	} = props;

	
	return (
		<div className="Images">
			<div className="Images-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>

			<div className="Images-section">
				<div className="Images-title">Upload billede:</div>

				<form className="Images-uploadImagesForm">
					<input
						multiple
						type="file" 
						accept="image/*"
						name="image"
						className="Images-input"
						onChange={(event)=>{selectImagesToUpload(event);}}
					/>
					<div
						className={'Images-uploadBtn' + 
								((imagesToUpload && imagesToUpload !== null) ? '' : ' disabled') +
								(isUploading ? ' Images-uploadBtn--loading' : '')}
						onClick={(event)=>{uploadImages(event);}}
					>{isUploading ? 'Uploading ...' : 'Upload'}</div>
				</form>
				<div className="Images-errorMsg">{errorMsg}</div>
			</div>

			<div className="Images-section">
				<div className="Images-title">Billeder:</div>
				<div className="Images-uploadedImages">
					{isLoading && <span>Henter billeder ...</span>}
					{images.map((image, index) => {
						return (
							<div key={index} className="Images-image" >
								<img src={image.url} alt={image.id} title={image.fileName} />
								<div 
									onClick={() => {handleDeleteImage(image.location, image.fileName, image.id);}} 
									className="Images-imageDeleteBtn"
								/>
							</div>
						);
					})}
				</div>
			</div>

			{deleteErrorMsg && <div className="Images-errorPopup">
				<div onClick={() => {closeErrorPopup();}} className="Image-errorPopupContent">
					{deleteErrorMsg}
				</div>
			</div>}

		
		</div>
	);
};

Images.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isUploading: PropTypes.bool.isRequired,
	// isDeleting: PropTypes.bool.isRequired,
	images: PropTypes.array.isRequired,
	imagesToUpload: PropTypes.object,
	errorMsg: PropTypes.string,
	deleteErrorMsg: PropTypes.string,
	selectImagesToUpload: PropTypes.func.isRequired,
	uploadImages: PropTypes.func.isRequired,
	closeErrorPopup: PropTypes.func.isRequired,
	handleDeleteImage: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Images;
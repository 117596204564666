// import appConfig from 'config/app.config';

function setCookie(cname, cvalue) {
	var d = new Date();
	d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
	// let expires = (cname === appConfig.cookiesAcceptedCookieName ? 'expires=' + d.toUTCString() : '');
	let expires = 'expires=' + d.toUTCString();

	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function deleteCookie(cname) {
	setCookie(cname, '', 0);
}

export {
	setCookie,
	getCookie,
	deleteCookie
};
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Card from 'components/ui/card/card';
import CardFeedback from 'components/ui/card/card-feedback';
import './intro.scss';

const Intro = ({showFeedback, cardData, selectedOption, selectOption}) => {
	/* Card direction */
	let [cardDirection, setCardDirection] = useState('');

	if (
		(cardData && cardData.hasOwnProperty('button') && cardData.button !== null) ||
		(selectedOption && selectedOption.hasOwnProperty('feedback') && selectedOption.feedback !== null)
	) {
		return (
			<div className="Intro">
				<div className="Intro-cardContainer">
					<CardFeedback 
						feedbackType={(showFeedback ? 'feedback' : 'intro')}
						cardData={cardData}
						selectedOption={selectedOption}
						goToNextGameStep={() => {selectOption((selectedOption ? selectedOption : cardData.button));}}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="Intro">
			<div className="Intro-cardContainer">
				{cardData && 
					<Card 
						cardData={cardData} 
						cardDirection={cardDirection}
						setCardDirection={setCardDirection} 
						handleSelectOption={selectOption}
					/>}
			</div>
		</div>	
	);
};

Intro.defaultProps = {
	cardData: null
};

Intro.propTypes = {
	showFeedback: PropTypes.bool.isRequired,
	cardData: PropTypes.object,
	selectedOption: PropTypes.object,
	selectOption: PropTypes.func.isRequired
};

export default Intro;
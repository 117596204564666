import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getImageUrl, saveImages, deleteImage} from 'helpers/storage-helper';
import Images from './images';

class ImagesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isUploading: false,
			isDeleting: false,
			images: [],
			selectedImageId: null,
			selectedImageFileName: null,
			prevSelectedImageFileName: null,
			imagesToUpload: null,
			errorMsg: null,
			deleteErrorMsg: null
		};
		this.unsubscribeUploadedImages = null;
	};


	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		this.subscribeToUploadedImages().then(() => {
			this.setState({isLoading: false});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeUploadedImages) this.unsubscribeUploadedImages();
	}

	/**
	 * Subscribe to images
	 */
	subscribeToUploadedImages = () => {
		return new Promise((resolve)=>{
			if (this.unsubscribeUploadedImages !== null) this.unsubscribeUploadedImages();
			const db = firebase.firestore();	
			this.unsubscribeUploadedImages = db.collection(appConfig.imagesDbName).onSnapshot((querySnapshot) => {
				let images = [];
				querySnapshot.docs.forEach((doc) => {
					let data = doc.data();
					data.id = doc.id;
					images.push(data);
				});
				
				if (images.length > 0) {
					let promises = [];
					images.forEach((image) => {promises.push(getImageUrl(image.location, image.fileName));});
					Promise.all(promises).then(
						(response) => {
							response.forEach((imgData, index) => {
								if (imgData && imgData.hasOwnProperty('fileName') && imgData.hasOwnProperty('url')) {
									images[index].url = imgData.url;
								} else {
									images[index].url = null;
									console.error(imgData);
								}
							});
							this.setState({images: images}, () => {resolve({status: 'ok'});});
						});
				} else {resolve();}
			}, (error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	}

	/**
	 * Select image to upload
	 * @param {object} event 
	 */
	selectImagesToUpload = (event) => {
		this.setState({imagesToUpload: event.target.files});	
	}

	/**
	 * Upload selected images
	 */
	uploadImages = () => {
		if (this.state.isLoading || this.state.isUploading) return;

		this.setState({isUploading: true}, () => {
			saveImages('cards', this.state.imagesToUpload).then((response) => {
				let errorMsg = null;
				if (response.failedUploads.length > 0) {
					errorMsg = 'Følgende filer blev ikke uploadet: ';
					response.failedUploads.forEach((fileName) => {
						errorMsg += fileName + ', ';
					});
				}
				this.setState({isUploading: false, imagesToUpload: null, errorMsg});
			}).catch((error) => {
				let errorMsg = 'Fejl: ' + error;
				this.setState({errorMsg: errorMsg, isUploading: false});
			});
		});
	}

	/**
	 * Delete image (if it is not used by a card)
	 * @param {string} location 
	 * @param {string} fileName 
	 * @param {string} imageId 
	 */
	handleDeleteImage = (location, fileName, imageId) => {
		if (this.state.isLoading || this.state.isUploading || this.state.isDeleting) return;

		this.setState({isDeleting: true}, () => {
			const db = firebase.firestore();	
			db.collection(appConfig.cardsDbName).where('imageFile', '==', fileName).get().then((querySnapshot) => {
				let cardsUsingImage = [];
				querySnapshot.forEach((doc) => {
					cardsUsingImage.push((doc.id));
				});
				if (cardsUsingImage.length === 0) {
					db.collection(appConfig.surveysDbName).get().then((querySnapshot) => {
						let surveysUsingImage = [];
						querySnapshot.forEach((doc) => {
							let surveyData = doc.data();
							let isUsingImage = false;
							surveyData.steps.forEach((step) => {
								if (step && step.image && step.image === fileName) isUsingImage = true;
							});
							if (isUsingImage) surveysUsingImage.push((doc.id));
						});
						if (surveysUsingImage.length === 0) {
							deleteImage(location, fileName, imageId).then((response) => {
								let errorMsg = null;
								if (response.status === 'error') {
									console.error(response.error);
									errorMsg = response.error;
								}
								this.setState({isDeleting: false, deleteErrorMsg: errorMsg});
							}).catch((error) => {
								console.error(error);
								this.setState({isDeleting: false, deleteErrorMsg: error});
							});
						} else {
							let errorMsg = 'Kan ikke slettes. Følgende surveys bruger dette billede: ';
							surveysUsingImage.forEach((surveyId) => {return errorMsg += surveyId + ', ';});
							this.setState({deleteErrorMsg: errorMsg, isDeleting: false});
						}
					});
				} else {
					let errorMsg = 'Kan ikke slettes. Følgende kort bruger dette billede: ';
					cardsUsingImage.forEach((cardId) => {return errorMsg += cardId + ', ';});
					this.setState({deleteErrorMsg: errorMsg, isDeleting: false});
				}
			}).catch((error) => {
				console.error(error);
				this.setState({deleteErrorMsg: error, isDeleting: false});
			});
		});
	}

	/**
	 * Close error popup
	 */
	closeErrorPopup = () => {
		this.setState({errorMsg: null, deleteErrorMsg: null});
	}

	/**
	 * Render component
	 */
	render() {
		return (
			<Images
				isLoading={this.state.isLoading}
				isUploading={this.state.isUploading}
				isDeleting={this.state.isDeleting}
				images={this.state.images}
				errorMsg={this.state.errorMsg}
				deleteErrorMsg={this.state.deleteErrorMsg}
				selectImagesToUpload={this.selectImagesToUpload}
				uploadImages={this.uploadImages}
				handleDeleteImage={this.handleDeleteImage}
				closeErrorPopup={this.closeErrorPopup}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

ImagesController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default ImagesController;
import appConfig from 'config/app.config';
import {sortArrayByProperty} from './array-helper';
import {getImageUrl} from './storage-helper';

/**
 * Load areas data
*/
function loadAreasData(db) {
	return new Promise((resolve)=>{
		db.collection(appConfig.areasDbName).onSnapshot((querySnapshot) => {
			let areasData = [];
			querySnapshot.docs.forEach((doc) => {
				let data = doc.data();
				data.id = parseInt(doc.id);
				areasData.push(data);
			});
			resolve({status: 'ok', areasData});
		});
	});
}


/**
	 * Load decks & cards from firestore
	 */
function loadDecksData(db) {
	return new Promise((resolve, reject)=>{

		/* Get decks */
		db.collection(appConfig.decksDbName).get().then((querySnapshot) => {
			let decksData = [];
			querySnapshot.forEach((doc) => {
				let deckObj = doc.data();
				deckObj.id = doc.id;
				deckObj.cards = [];
				decksData.push(deckObj);
			});
			decksData = sortArrayByProperty(decksData, 'index', 'ASC');

			/* Get cards */
			db.collection(appConfig.cardsDbName).get().then((querySnapshot) => {
				let cardsData = [];
				querySnapshot.forEach((doc) => {
					let cardObj = doc.data();
					cardObj.id = doc.id;
					cardObj.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
					cardsData.push(cardObj);
				});
					
				/* Sort cards into decks */
				decksData.forEach((deck) => {
					let cardsInDeck = cardsData.filter((card) => {return card.deckId === deck.id;});
					cardsInDeck = sortArrayByProperty(cardsInDeck, 'index', 'ASC');
					deck.cards = cardsInDeck;
				});

				/* Resolve */
				resolve({status: 'ok', decksData});
			}, (error) => {
				/* Get cards error */
				reject({status: 'error', error: error});
			});

		}, (error) => {
			/* Get decks error */
			reject({status: 'error', error: error});
		});
	});
}

/**
	 * Load card images
	 */
function loadCardImages(db) {
	return new Promise((resolve, reject)=>{
		db.collection(appConfig.imagesDbName).onSnapshot((querySnapshot) => {
			let cardImages = [];
			querySnapshot.docs.forEach((doc) => {
				let data = doc.data();
				data.id = doc.id;
				cardImages.push(data);
			});

			if (cardImages.length > 0) {
				let promises = [];
				cardImages.forEach((image) => {promises.push(getImageUrl(image.location, image.fileName));});
				Promise.all(promises).then(
					(response) => {
						response.forEach((imgData, index) => {
							if (imgData && imgData.hasOwnProperty('fileName') && imgData.hasOwnProperty('url')) {
								cardImages[index].url = imgData.url;
							} else {
								cardImages[index].url = null;
								console.error(imgData);
							}
						});
						resolve({status: 'ok', cardImages});
					});
			} else {resolve({status: 'ok', cardImages});}
		});
	});
}

/**
	 * Load missions data
	 */
function loadMissionsData(db) {
	return new Promise((resolve, reject)=>{
		db.collection(appConfig.missionsDbName).onSnapshot((querySnapshot) => {
			let missionsData = [];
			querySnapshot.docs.forEach((doc) => {
				let data = doc.data();
				missionsData.push(data);
			});
			resolve({status: 'ok', missionsData});
		});
	});
}

/**
	 * Load surveys data
	 */
function loadSurveysData(db) {
	return new Promise((resolve, reject)=>{
		db.collection(appConfig.surveysDbName).onSnapshot((querySnapshot) => {
			let surveysData = [];
			querySnapshot.docs.forEach((doc) => {
				let data = doc.data();
				surveysData.push(data);
			});
			resolve({status: 'ok', surveysData});
		});
	});
}


export {
	loadAreasData,
	loadDecksData,
	loadCardImages,
	loadMissionsData,
	loadSurveysData
};
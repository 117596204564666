import React from 'react';
import PropTypes from 'prop-types';
import {sortArrayByProperty} from 'helpers/array-helper';
import {generalUiTexts} from 'data/ui-texts';
import './missions.scss';

const Missions = (props) => {
	let {
		playerData,
		decksData,
		missionsData,
		closePopup,
	} = props;

	let missions = [...missionsData];
	missions.forEach((mission) => {
		let mazeId = null;
		decksData.forEach((deck) => {
			if (mazeId !== null) return;
			if (deck && deck.cards && deck.cards.length > 0) {
				deck.cards.forEach((card) => {
					if (mazeId !== null) return;
					if (
						card && 
						(
							(
								card.hasOwnProperty('answerA') && 
								card.answerA.mission && 
								card.answerA.mission.missionId === mission.id
							) ||
							(
								card.hasOwnProperty('answerB') && 
								card.answerB.mission && 
								card.answerB.mission.missionId === mission.id
							)
						)
					) {
						mazeId = (card.mazeId ? card.mazeId : 1);
					}
				});
			}
		});
		mission.mazeId = mazeId;
	});

	missions = sortArrayByProperty(missions, 'mazeId', 'ASC', 'id', 'ASC');
	let availableMissions = missions.filter((mission) => {
		return mission.mazeId <= playerData.mazeId;
	});
	let playerCompletedMissions = playerData.missionsCompleted ? playerData.missionsCompleted : [];
	return (
		<div className="Missions2">
			<div className="Missions-title">{generalUiTexts.missions}</div>
			<div className="Missions-missions">
				<div className="Missions-section">
					<div className="Missions-sectionTitle">{generalUiTexts.new}</div>
					{availableMissions.filter((mission) => {
						return (!playerCompletedMissions.some((m) => {return mission.id === m.id;}));
					}).map((mission) => {
						return <div key={mission.id} className="Missions-mission">{mission.title}</div>;
					})}
				</div>
				<div className="Missions-section completed">
					<div className="Missions-sectionTitle">{generalUiTexts.completed}</div>
					{availableMissions.filter((mission) => {
						return (playerCompletedMissions.some((m) => {return mission.id === m.id;}));
					}).map((mission) => {
						return <div key={mission.id} className="Missions-mission">{mission.title}</div>;
					})}
				</div>
			</div>
			<div className="Missions-closeBtn" onClick={closePopup}>{generalUiTexts.close}</div>
		</div>				
	);
};

Missions.propTypes = {
	playerData: PropTypes.object.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default Missions;
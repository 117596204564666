import React from 'react';
import PropTypes from 'prop-types';
import Intro from './intro';

class IntroController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			deckData: null,
			deckId: 'intro',
			cardId: 'intro-1',
			showFeedback: false,
			selectedOption: null
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let deckData = [];
		if (this.props.decksData.some((deck) => {return deck.id === 'intro';})) {
			deckData = this.props.decksData.filter((deck) => {return deck.id === 'intro';})[0];
		}
		this.setState({deckData, isLoading: false});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.timeout) clearTimeout(this.timeout);
	}

	/**
	 * Player selects card option (left / right)
	 * @param {object} optionData 
	 */
	selectOption = (optionData) => {
		if (this.timeout) clearTimeout(this.timeout);

		/* Show feedback */
		if (optionData.feedback && !this.state.showFeedback) {
			setTimeout(() => {this.setState({showFeedback: true, selectedOption: optionData});}, 500);
			return;
		}

		/* Continue to onboarding */
		if (optionData.nextCard.type === 'mazeGame') {
			/* Mark intro as seen & start playing */
			this.timeout = setTimeout(() => {
				this.setState({cardId: null, showFeedback: false, selectedOption: null}, () => {
					this.props.updatePlayerData({introSeen: true});
					this.props.handleShowPage('mazeGame');
				});
			}, 1000);	
			return;
		}

		/* Show next card */
		let cardId = optionData.nextCard.cardId;
		/* Update state */
		this.timeout = setTimeout(() => {
			this.setState({cardId: null, showFeedback: false, selectedOption: null}, () => {
				this.setState({cardId});
			});
		}, 500);	
	}

	/**
	 * Render component
	 */
	render() {
		let cardData = (
			this.state.deckData && 
			this.state.deckData.cards.some((card) => {return card.id === this.state.cardId;})
				? this.state.deckData.cards.find((card) => {return card.id === this.state.cardId;})
				: null
		);
		
		return (
			<Intro
				showFeedback={this.state.showFeedback}
				cardData={cardData}
				deckData={this.state.deckData}
				selectedOption={this.state.selectedOption}
				selectOption={this.selectOption}
			/>
		);
	}
}

IntroController.propTypes = {
	decksData: PropTypes.array.isRequired,
	handleShowPage: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired
};

export default IntroController;
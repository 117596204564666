import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {loadAreasData, loadMissionsData, loadSurveysData} from 'helpers/game-content-helper';
import Stats from './stats';

class StatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isDownloading: false,
			usersData: [],
			areasData: [],
			missionsData: [],
			surveysData: [],
		};
	};


	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Load game content and users data */
		const db = firebase.firestore();
		Promise.all([
			loadAreasData(db),
			loadMissionsData(db), 
			loadSurveysData(db), 
			this.loadUsersData(db)
		]).then((responses) => {
			const areasData = responses[0].areasData;
			const missionsData = responses[1].missionsData;
			const surveysData = responses[2].surveysData;
			const usersData = responses[3].usersData;
	
			this.setState({isLoading: false, areasData, missionsData, surveysData, usersData });
		});
	}

	/**
	 * Load users data
	 * @param {string} db 
	 * @returns 
	 */
	loadUsersData = (db) => {
		return new Promise((resolve)=>{
			db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let usersData = [];
				querySnapshot.docs.forEach((doc) => {usersData.push({id: doc.id, ...doc.data()});});
				resolve({status: 'ok', usersData});
			}, (error) => {resolve({status: 'error', error: error, usersData: []});});
		});
	}


	/**
	 * Render component
	 */
	render() {
		return (
			<Stats
				isLoading={this.state.isLoading}
				isDownloading={this.state.isDownloading}
				authSessionData={this.props.authSessionData}
				usersData={this.state.usersData}
				areasData={this.state.areasData}
				missionsData={this.state.missionsData}
				surveysData={this.state.surveysData}
				goToPage={this.props.goToPage}
			/>
		);
	}
}

StatsController.propTypes = {
	authSessionData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default StatsController;
const loginUiTexts = {
	email: 'e-mail',
	password: 'password',
	login: 'Log ind',
	logout: 'Log ud',
	unknownError: 'Ukendt fejl. Tjek din internetforbindelse.',
	'auth/user-not-found': 'Der eksisterer ikke nogen bruger med den e-mail.',
	'auth/wrong-password': 'Forkert password.',
	'empty-fields': 'Du skal udfylde alle felter.'
};

const statsUiTexts = {
	back: 'Tilbage',
	stats: 'Statistik',
	numberOfPlayers: 'Antal spillere',
	download: 'Download'
};



export {
	loginUiTexts,
	statsUiTexts
};
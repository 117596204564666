import React from 'react';
import PropTypes from 'prop-types';
import './range-slider.scss';

const RangeSlider = ({type, value, handleUpdateValue}) => {
	let thumbColor = Math.floor(value / 10.);
	return (
		<div className={'RangeSlider RangeSlider--' + thumbColor + (type ? ' RangeSlider--' + type : '')}>
			<input 
				type="range" 
				name="input" 
				disabled={false}
				min="0"
				max="100"
				step="1"
				value={value}
				onChange={(event)=>{handleUpdateValue(event);}} 
			/>
		</div>				
	);
};

RangeSlider.defaultProps = {
	type: null,
};

RangeSlider.propTypes = {
	type: PropTypes.string,
	value: PropTypes.number.isRequired,
	handleUpdateValue: PropTypes.func.isRequired
};

export default RangeSlider;
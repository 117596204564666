import React from 'react';
import PropTypes from 'prop-types';
import './mission-popup.scss';

const MissionPopup = ({isEditing, isSaving, selectedMission, selectMission, updateMission, saveMission}) => {

	let saveMissionBtnClass = 'MissionPopup-saveMissionBtn' + 
	(isEditing ? ' MissionPopup-saveMissionBtn--active' : '') + 
	(isEditing && isSaving ? 'MissionPopup-saveMissionBtn--saving' : '');



	return (
		<div className="MissionPopup">
			<div className="MissionPopup-content">
				<div className="MissionPopup-backBtn" onClick={() => {selectMission(null);}} />
				<div className="MissionPopup-missionId"><span>ID: </span>{selectedMission.id}</div>

				{/* Save button */}
				<div className={saveMissionBtnClass} onClick={() => {saveMission();}}>Gem</div>
				
				{/* Mission data */}
				<div className="MissionPopup-section MissionPopup-section--mission">
					<div className="MissionPopup-missionInfo">
						<span>Titel:</span>
						<input 
							type="text"
							name="title"
							value={selectedMission.title}
							onChange={(event) => {updateMission(event);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};



MissionPopup.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	selectedMission: PropTypes.object.isRequired,
	selectMission: PropTypes.func.isRequired,
	updateMission: PropTypes.func.isRequired,
	saveMission: PropTypes.func.isRequired
};

export default MissionPopup;
import appConfig from 'config/app.config';

const missionRequirementTypes = (appConfig.env === 'en' 
  ? [
    {id: 1, title: 'ingen requirements', value: 'none'}, 
    {id: 2, title: 'requirement: secret', value: 'secret'}  
  ]
  : [
    {id: 1, title: 'ingen krav', value: 'none'}, 
    {id: 2, title: 'krav: hemmelighed', value: 'secret'}
  ]
);

export {
  missionRequirementTypes
};

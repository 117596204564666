import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import AdminLogin from './admin-login';
import Admin from './admin';

class AdminController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isLoading: false,
			isLoggedIn: false,
			email: null,
			password: null,
			feedback: null,
			page: 'admin',
			authSessionData: {
				userId: null,
				userTokenId: null,
				email: null,
			}
		};
		this.unsubscribeSnapshot = null;
		this.unsubscribeOnAuthStateChanged = null;
	};

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.checkIfLoggedIn();
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeSnapshot !== null) this.unsubscribeSnapshot();
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	}

	/**
	 * Subscribe to login status
	 */
	checkIfLoggedIn = () => {
		this.setState({isCheckingLogin: true});

		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user && user.email) {
				user.getIdToken().then((idToken) => {
					this.setState({
						isCheckingLogin: false,
						isLoggedIn: true,
						authSessionData: {
							userId: user.uid,
							userTokenId: idToken,
							email: user.email
						}
					});
				});
			} else if (user) {
				/* Logged in as player */
				this.handleLogout();
			}
		});
	}


	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	}
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		event.preventDefault();
		this.setState({isLoading: true});
		let email = this.state.email;
		let password = this.state.password;
		
		let error = null;
		if (!email || !password) error = 'empty-fields';
		if (!error) {
			firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
				let errorMsg = error.code;
				this.setState({
					feedback: errorMsg,
					isLoading: false
				});
			});
		} else {
			const msg = error;
			this.setState({
				feedback: msg,
				isLoading: false
			});
		}
	}	

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false
		});
	}

	/**
	 * Go to admin page
	 * @param {string} page 
	 */
	goToPage = (page) => {
		this.setState({page: page});
	}


	/**
	 * Render component
	 */
	render = () => {
		if (!this.state.isLoggedIn) {
			return (
				<AdminLogin 
					isLoading={this.state.isLoading}
					email={this.state.email}
					password={this.state.password}
					feedback={this.state.feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}


		return (
			<Admin 
				authSessionData={this.state.authSessionData}
				page={this.state.page}
				goToPage={this.goToPage}
				handleLogout={this.handleLogout}
			/>
		);
	}
};

export default AdminController;
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/ui/select/select';
import './area-popup.scss';

const AreaPopup = ({isEditing, isSaving, selectedArea, areas, selectArea, updateArea, saveArea}) => {

	let saveAreaBtnClass = 'AreaPopup-saveAreaBtn' + 
	(isEditing ? ' AreaPopup-saveAreaBtn--active' : '') + 
	(isEditing && isSaving ? 'AreaPopup-saveAreaBtn--saving' : '');

	let areaOptions = areas.filter((area) => {return area.id !== selectedArea.id;});
	areaOptions.push({
		id: 'exit',
		title: 'Udgang'
	});
	if (selectedArea.portal) areaOptions = [{id: -1, title: '-'}].concat(areaOptions);

	let imageOptions = ['maze-1.svg', 'maze-2.svg', 'maze-3.svg'];

	return (
		<div className="AreaPopup">
			<div className="AreaPopup-content">
				<div className="AreaPopup-backBtn" onClick={() => {selectArea(null);}} />
				<div className="AreaPopup-areaId"><span>ID: </span>{selectedArea.id}</div>

				{/* Save button */}
				<div className={saveAreaBtnClass} onClick={() => {saveArea();}}>Gem</div>
				
				{/* Area data */}
				<div className="AreaPopup-section AreaPopup-section--area">
					<div className="AreaPopup-areaInfo">
						<span>Titel:</span>
						<input 
							type="text"
							name="title"
							value={selectedArea.title}
							onChange={(event) => {updateArea(event);}}
						/>
					</div>
					<div className="AreaPopup-areaInfo">
						<span>Billede:</span>
						<Select
							type="imageFile"
							options={imageOptions}
							defaultValue=''
							selectedId={(selectedArea.imageFile ? selectedArea.imageFile : '')}
							onSelect={updateArea}
						/>
					</div>
					<div className="AreaPopup-areaInfo">
						<span>Næste område:</span>
						<Select
							type="nextAreaId"
							extraParams={['portal']}
							options={areaOptions}
							defaultValue=''
							selectedId={(selectedArea.portal ? selectedArea.portal.nextAreaId : '')}
							onSelect={updateArea}
						/>
					</div>
					{selectedArea.portal && <div className="AreaPopup-areaInfo">
						<span>Antal nøgler:</span>
						<input 
							className={'AreaPopup-input' + (selectedArea.portal.keys < 1 ? ' red' : '')}
							type="number"
							name="keys"
							value={selectedArea.portal.keys}
							onChange={(event) => {updateArea(event, 'portal');}}
						/>
					</div>}
				</div>
			</div>
		</div>
	);
};



AreaPopup.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	selectedArea: PropTypes.object.isRequired,
	areas: PropTypes.array.isRequired,
	selectArea: PropTypes.func.isRequired,
	updateArea: PropTypes.func.isRequired,
	saveArea: PropTypes.func.isRequired
};

export default AreaPopup;
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import './the-end.scss';

const TheEnd = ({handleCloseTheEndPopup}) => {
	let [showCard, setShowCard] = useState(false);

	if (!showCard) {
		return (
			<div className="TheEnd">
				<div className="TheEnd-text">{generalUiTexts.endOfGamePopup.text}</div>
				<div className="TheEnd-btn" onClick={() => {setShowCard(true);}}>{generalUiTexts.ok}</div>
			</div>				
		);
	}

	return (
		<div className="TheEnd card">
			<div className="TheEnd-cardContainer">
				<div className="TheEnd-card">
					<div className="TheEnd-cardText">{generalUiTexts.endOfGamePopup.text2}</div>
					<div 
						className="TheEnd-cardBtn" 
						onClick={() => {handleCloseTheEndPopup();}}
					>{generalUiTexts.ok}</div>
				</div>
			</div>
		</div>
	);
};

TheEnd.propTypes = {
	handleCloseTheEndPopup: PropTypes.func.isRequired
};

export default TheEnd;
import React from 'react';
import PropTypes from 'prop-types';
import {cookieUiTexts} from 'data/ui-texts';
import './cookie-consent.scss';

const CookieConsent = ({handleClosePopup}) => {
	return (
		<div className="CookieConsent">
			<div className="CookieConsent-text" dangerouslySetInnerHTML={{__html: cookieUiTexts.text}}/>
			<div className="CookieConsent-button" onClick={() => {handleClosePopup();}}>{cookieUiTexts.okBtn}</div>
		</div>
	);	
};

CookieConsent.propTypes = {
	handleClosePopup: PropTypes.func.isRequired
};

export default CookieConsent;
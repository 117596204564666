import React from 'react';
import PropTypes from 'prop-types';
import './tag-popup.scss';

const TagPopup = ({isEditing, isSaving, selectedTag, tags, selectTag, updateTag, saveTag, deleteTag}) => {

	let saveTagBtnClass = 'TagPopup-saveTagBtn' + 
	(isEditing ? ' TagPopup-saveTagBtn--active' : '') + 
	(isEditing && isSaving ? 'TagPopup-saveTagBtn--saving' : '');


	return (
		<div className="TagPopup">
			<div className="TagPopup-content">
				<div className="TagPopup-backBtn" onClick={() => {selectTag(null);}} />
				<div className="TagPopup-tagId"><span>ID: </span>{selectedTag.id ? selectedTag.id : '-'}</div>

				
				{/* Tag data */}
				<div className="TagPopup-section TagPopup-section--tag">
					<div className="TagPopup-tagInfo">
						<span>Titel:</span>
						<input 
							type="text"
							name="title"
							value={selectedTag.title}
							onChange={(event) => {updateTag(event);}}
						/>
					</div>
				</div>

				{/* Save button */}
				<div className={saveTagBtnClass} onClick={() => {saveTag();}}>Gem</div>

				{/* Delete */}
				<br />
				<div className="TagPopup-deleteTagBtn" onClick={() => {deleteTag();}}>Delete</div>
			</div>
		</div>
	);
};



TagPopup.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	selectedTag: PropTypes.object.isRequired,
	tags: PropTypes.array.isRequired,
	selectTag: PropTypes.func.isRequired,
	updateTag: PropTypes.func.isRequired,
	saveTag: PropTypes.func.isRequired,
	deleteTag: PropTypes.func.isRequired,
};

export default TagPopup;
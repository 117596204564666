import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as KeyHoleImg} from 'assets/images/portal/key-hole.svg';
import {ReactComponent as KeyHoleUnlockedImg} from 'assets/images/portal/key-hole-unlocked.svg';
import './portal-mini.scss';

const PortalMini = ({type, keys, unlockedKeys}) => {

	return (
		<div className={'PortalMini PortalMini--' + type}>
			{keys > 0 && <div className={'PortalMini-keyHoles PortalMini-keyHoles--' + keys}>
				{[...Array(keys)].map((_, index) => {
					let keyClass = 'PortalMini-keyHole PortalMini-keyHole--' + (index + 1);
					if ((index + 1) <= unlockedKeys) keyClass += ' PortalMini-keyHole--unlocked';
					return (
						<div key={index} className={keyClass}>
							{((index + 1) <= unlockedKeys ? <KeyHoleUnlockedImg /> : <KeyHoleImg />)}
						</div>
					);
				})}
			</div>}
		</div>
	);
};

PortalMini.propTypes = {
	type: PropTypes.string.isRequired,
	keys: PropTypes.number.isRequired,
	unlockedKeys: PropTypes.number.isRequired,
};

export default PortalMini;
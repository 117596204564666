import appConfig from 'config/app.config';


const surveyTypes = (appConfig.env === 'en' 
	? [
		{id: 'gameover', title: 'Game over', value: 'gameover'}, 
		{id: 'party', title: 'Party', value: 'party'}, 
		{id: 'dinner', title: 'Dinner', value: 'dinner'}, 
		{id: 'forest', title: 'Forest', value: 'forest'},
		{id: 'beach', title: 'Beach', value: 'beach'},
		{id: 'study', title: 'School', value: 'study'},
		{id: 'christmas', title: 'Christmas', value: 'christmas'},
		{id: 'specific', title: 'Specific', value: 'specific'}
	]
	: [
		{id: 'gameover', title: 'Gameover', value: 'gameover'}, 
		{id: 'party', title: 'Fest', value: 'party'}, 
		{id: 'dinner', title: 'Middag', value: 'dinner'}, 
		{id: 'forest', title: 'Skoven', value: 'forest'},
		{id: 'beach', title: 'Stranden', value: 'beach'},
		{id: 'study', title: 'Studie', value: 'study'},
		{id: 'christmas', title: 'Jul', value: 'christmas'},
		{id: 'specific', title: 'Specifik', value: 'specific'}
	]
);

const stepTypes = (appConfig.env === 'en' 
	? [
		{id: 'intro', title: 'Intro', value: 'intro'},
		{id: 'textarea', title: 'Text input', value: 'textarea'},
		{id: 'multiple-choice', title: 'Multiple choice', value: 'multiple-choice'},
		{id: 'slider', title: 'Slider', value: 'slider'},
		{id: 'tag-choose', title: 'Tag - choose', value: 'tag-choose'},
		{id: 'tag-feedback', title: 'Tag - feedback', value: 'tag-feedback'},
		{id: 'tag-mc', title: 'Tag - mc', value: 'tag-mc'},
	]
	: [
		{id: 'intro', title: 'Intro', value: 'intro'},
		{id: 'textarea', title: 'Tekst input', value: 'textarea'},
		{id: 'multiple-choice', title: 'Multiple choice', value: 'multiple-choice'},
		{id: 'slider', title: 'Slider', value: 'slider'},
		{id: 'tag-choose', title: 'Tag - choose', value: 'tag-choose'},
		{id: 'tag-feedback', title: 'Tag - feedback', value: 'tag-feedback'},
		{id: 'tag-mc', title: 'Tag - mc', value: 'tag-mc'},
	]
);

const yesNoOptions = (appConfig.env === 'en' 
	? [{id: 'yes', title: 'Yes'}, {id: 'no', title: 'No'}]
	: [{id: 'yes', title: 'Ja'}, {id: 'no', title: 'Nej'}]
);

export {
	surveyTypes,
	stepTypes,
	yesNoOptions
};
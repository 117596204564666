import appConfig from 'config/app.config';

const cardTypes = (appConfig.env === 'en'
	? [
		{id: 'R', title: 'R (random)', value: true},
		{id: 'P', title: 'P (pick)', value: false}
	]
	: [
		{id: 'R', title: 'R (random)', value: true},
		{id: 'P', title: 'P (pluk)', value: false}
	]
);

const nextCardTypes = (appConfig.env === 'en' 
	? [
		{id: 'random', title: 'random', value: 'random'}, 
		{id: 'specific', title: 'specific', value: 'specific'}
	]
	: [
	{id: 'random', title: 'random', value: 'random'}, 
	{id: 'specific', title: 'specific', value: 'specific'}
]
);

export {cardTypes, nextCardTypes};
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

env = 'en'; // ENGLISHMODE

let firebaseConfig = {};

/* Connect to firebase: test */
if (env === 'development' || env === 'da') {
	firebaseConfig = {
		apiKey: 'AIzaSyCVPlR3TxG7VGgE0M9ocE7bOuLaZhJkqcE',
		authDomain: 'cgl-mazeout-showcase.firebaseapp.com',
		projectId: 'cgl-mazeout-showcase',
		storageBucket: 'cgl-mazeout-showcase.appspot.com',
		messagingSenderId: '582957811772',
		appId: '1:582957811772:web:05986448bfc3d233a41b39'
	};
}


/* Connect to firebase: english version */
if (env === 'en') {
	firebaseConfig = {
		apiKey: 'AIzaSyC3NEhghfDbt9CkdLYjNLzYP-XsbmVY82c',
		authDomain: 'cgl-mazeout-showcase-en.firebaseapp.com',
		projectId: 'cgl-mazeout-showcase-en',
		storageBucket: 'cgl-mazeout-showcase-en.appspot.com',
		messagingSenderId: '856674726116',
		appId: '1:856674726116:web:4c8b202fddeea20a9f7cf1'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);


export default firebase.firestore;
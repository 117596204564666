import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import ReserveUsers from './reserve-users';

class ReserveUsersController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errMsg: null,
			users: [],
			reservedUsers: [],
			selectedReservedUser: null,
			isEditing: false,
			isSaving: false,
			isCreating: false,
			isDeleting: false
		};
		this.unsubscribeUsers = null;
		this.unsubscribeReserved = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		Promise.all([
			this.subscribeToUsers(),
			this.subscribeToReserveUsers()
		]).then(() => {
			this.setState({isLoading: false});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false, errMsg: error});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeUsers) this.unsubscribeReserved();
		if (this.unsubscribeUsers) this.unsubscribeReserved();
	}

	/**
	 * Subscribe to users
	 */
	subscribeToUsers = () => {
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeUsers = db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let users = [];
				if (!querySnapshot.empty) {
					querySnapshot.docs.forEach((doc) => {users.push(doc.id);});
				}
				this.setState({users}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to reserved users
	 */
		subscribeToReserveUsers = () => {
			if (this.unsubscribeReserved !== null) this.unsubscribeReserved();
			const db = firebase.firestore();
			return new Promise((resolve)=>{
				this.unsubscribeReserved = db.collection(appConfig.reservedUsersDbName).onSnapshot((querySnapshot) => {
					let reservedUsers = [];
					if (!querySnapshot.empty) {
						querySnapshot.docs.forEach((doc) => {
							if (doc.data()) {
								reservedUsers.push({id: doc.id, ...doc.data()});
							}
						});
					}
					this.setState({reservedUsers}, () => {resolve({status: 'ok'});});
				}, (error) => {resolve({status: 'error', error: error});});
			});
		}

	/**
	 * Select area
	 * @param {string} userId 
	 */
	selectReservedUser = (userId) => {
		if (userId === 'new') {
			const selectedReservedUser = {id: null, name: '', timeout: dayjs(new Date()).format('YYYY-MM-DD')};
			this.setState({selectedReservedUser});
		} else {
			const selectedReservedUser = this.state.reservedUsers.find((u) => {return u.id === userId;});
			if (selectedReservedUser) {
				this.setState({selectedReservedUser});
			} else {
				this.setState({selectedReservedUser: null, isEditing: false});
			}
		}
	}

	/**
	 * Update reserved user
	 * @param {object} event 
	 */
	updateReservedUser = (event) => {
		if (this.state.isSaving || this.state.isCreating) return;
		let selectedReservedUser = JSON.parse(JSON.stringify(this.state.selectedReservedUser));
		const propName = event.target.name;
		const propValue = event.target.value;
		selectedReservedUser[propName] = propValue;
		let errMsg = null;
		if (selectedReservedUser.name.length < appConfig.userNameMinLength) {
			errMsg = 'Brugernavn for kort';
		}
		if (!errMsg && selectedReservedUser.name.length > appConfig.userNameMaxLength) {
			errMsg = 'Brugernavn for langt';
		}
		if (!errMsg && this.state.users.indexOf(selectedReservedUser.name) >= 0) {
			errMsg = 'Brugernavn eksisterer allerede';
		}
		if (
			!errMsg && 
			this.state.reservedUsers.some((u) => {
				return (
					u.name === selectedReservedUser.name &&
					(!selectedReservedUser.id || u.id !== selectedReservedUser.id)
				);
			})
		) {
			errMsg = 'Brugernavn er allerede reserveret';
		}
		this.setState({selectedReservedUser, isEditing: true, errMsg});
	}



	/**
	 * Save changes
	 */
	saveReservedUser = () => {
		if (this.state.isEditing && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {

				let selectedReservedUser = JSON.parse(JSON.stringify(this.state.selectedReservedUser));
			
				const updates = {
					name: selectedReservedUser.name,
					timeout: selectedReservedUser.timeout
				};
				const db = firebase.firestore();
				if (!selectedReservedUser.id) {
					db.collection(appConfig.reservedUsersDbName).add(updates).then(() => {
						this.setState({isEditing: false, isSaving: false, selectedReservedUser: null});
					}, (error) => {
						console.error(error);
						this.setState({isSaving: false});
					});

				} else {
					db.collection(appConfig.reservedUsersDbName).doc(selectedReservedUser.id)
						.update(updates).then(() => {
							this.setState({isEditing: false, isSaving: false, selectedReservedUser: null});
						}, (error) => {
							console.error(error);
							this.setState({isSaving: false});
						});
				}
			});
		}
	}

	deleteReservedUser = () => {
		if (!this.state.isSaving && !this.state.isDeleting) {
			this.setState({isDeleting: true}, () => {
				let selectedReservedUser = JSON.parse(JSON.stringify(this.state.selectedReservedUser));
				if (selectedReservedUser.id) {
					const db = firebase.firestore();
					db.collection(appConfig.reservedUsersDbName).doc(selectedReservedUser.id)
						.delete().then(() => {
							this.setState({isEditing: false, isDeleting: false, selectedReservedUser: null});
						}, (error) => {
							console.error(error);
							this.setState({isDeleting: false});
						});
				}	
			});
		}
	}


	/**
	 * Render component
	 */
	render = () => {
		return (
			<ReserveUsers 
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				isCreating={this.state.isCreating}
				isDeleting={this.state.isDeleting}
				errMsg={this.state.errMsg}
				users={this.state.users}
				reservedUsers={this.state.reservedUsers}
				selectedReservedUser={this.state.selectedReservedUser}
				selectReservedUser={this.selectReservedUser}
				updateReservedUser={this.updateReservedUser}
				saveReservedUser={this.saveReservedUser}
				deleteReservedUser={this.deleteReservedUser}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

ReserveUsersController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default ReserveUsersController;
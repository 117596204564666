import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Survey from 'components/survey/survey';
import './survey-preview.scss';

const SurveyPreview = ({type, surveyData, showSurveyPreview}) => {

	let [stepIndex, setStepIndex] = useState(0);

	let [survey, setSurvey] = useState(surveyData);
	const toggleOption = (optionId) => {
		let newSurvey = JSON.parse(JSON.stringify(survey));
		if (newSurvey.steps && newSurvey.steps.length > stepIndex && newSurvey.steps[stepIndex].options) {
			const optionIndex = newSurvey.steps[stepIndex].options.findIndex((o) => {return o.id === optionId;});
			if (optionIndex >= 0) {
				newSurvey.steps[stepIndex].options[optionIndex].selected = 
					!newSurvey.steps[stepIndex].options[optionIndex].selected;
				if (newSurvey.steps[stepIndex].type === 'tag-choose') {
					/* Only one option can be selected */
					newSurvey.steps[stepIndex].options.forEach((_, index) => {
						if (index !== optionIndex) newSurvey.steps[stepIndex].options[index].selected = false;
					});
				}
				setSurvey(newSurvey);
			}			
		}
	};

	const handleConfirmStep = () => {
		if (stepIndex + 1 < surveyData.steps.length) {
			setStepIndex(stepIndex + 1);
		}

	};

	return (
		<div className={'SurveyPreview SurveyPreview--' + type}>

			<div className="SurveyPreview-content">
				<div className="SurveyPreview-closeBtn" onClick={() => {showSurveyPreview(null);}} />
				<div className="SurveyPreview-survey">
					<Survey 
						isPreview={true}
						stepIndex={stepIndex}
						surveyId={surveyData.id}
						surveyData={survey}
						handleUpdateInput={() => {return null;}}
						toggleOption={toggleOption}
						handleConfirmStep={handleConfirmStep}
						handleSaveSurvey={() => {showSurveyPreview(null);}}
					/>
				</div>
			</div>
		</div>
	);
};


SurveyPreview.propTypes = {
	type: PropTypes.string.isRequired,
	surveyData: PropTypes.object.isRequired,
	showSurveyPreview: PropTypes.func.isRequired
};

export default SurveyPreview;
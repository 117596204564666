import React from 'react';
import PropTypes from 'prop-types';
import ReserveUsersPopup from './reserve-users-popup';
import './reserve-users.scss';

const ReserveUsers = (props) => {
	let {
		isEditing, 
		isSaving,
		isCreating,
		isDeleting,
		errMsg,
		users,
		reservedUsers,
		selectedReservedUser,
		selectReservedUser,
		updateReservedUser, 
		saveReservedUser,
		deleteReservedUser,
		goToPage,
	} = props;


	return (
		<div className="ReserveUsers">
			<div className="ReserveUsers-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>

			{/* ReserveUsers */}
			<div className="ReserveUsers-section">
				<div className="ReserveUsers-title">Reserverede brugernavne:</div>
				<div className="ReserveUsers-header">
					<div className="ReserveUsers-userName">Brugernavn</div>
					<div className="ReserveUsers-timeout">Kan oprettes fra</div>
					<div className="ReserveUsers-status">Status</div>
				</div>
				{reservedUsers.map((user, index) => {
					const isCreated = users.some((u) => {return u === user.name;});
					return (
						<div key={index} className="ReserveUsers-row" onClick={() => {selectReservedUser(user.id);}} >
							<div className="ReserveUsers-userName">{user.name}</div>
							<div className="ReserveUsers-timeout">{user.timeout}</div>
							<div className="ReserveUsers-status">{isCreated ? 'oprettet' : '-'}</div>
						</div>
					);
				})}
				<div 
					className={'ReserveUsers-newUserBtn' + (isCreating ? ' ReserveUsers-newUserBtn--saving' : '') } 
					onClick={() => {selectReservedUser('new');}}
				>Reserver nyt brugernavn</div>
			</div>

			{/* Edit area popup */}
			{selectedReservedUser &&
				<ReserveUsersPopup 
					isEditing={isEditing}
					isSaving={isSaving}
					isDeleting={isDeleting}
					errMsg={errMsg}
					users={users}
					selectedReservedUser={selectedReservedUser} 
					reservedUsers={reservedUsers}
					updateReservedUser={updateReservedUser} 
					selectReservedUser={selectReservedUser}
					saveReservedUser={saveReservedUser}
					deleteReservedUser={deleteReservedUser}
				/>
			}

		</div>
	);
};

ReserveUsers.defaultProps = {
	selectedReservedUser: null
};

ReserveUsers.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	isDeleting: PropTypes.bool.isRequired,
	errMsg: PropTypes.string,
	users: PropTypes.array.isRequired,
	reservedUsers: PropTypes.array.isRequired,
	selectedReservedUser: PropTypes.object,
	selectReservedUser: PropTypes.func.isRequired,
	updateReservedUser: PropTypes.func.isRequired,
	saveReservedUser: PropTypes.func.isRequired,
	deleteReservedUser: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default ReserveUsers;
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './reserve-users-popup.scss';

const ReserveUsersPopup = (props) => {
	const {
		isEditing, 
		isSaving, 
		isDeleting,
		selectedReservedUser, 
		errMsg,
		selectReservedUser, 
		updateReservedUser, 
		saveReservedUser,
		deleteReservedUser
	} = props;

	

	let saveAreaBtnClass = 'ReserveUsersPopup-saveReservedUserBtn' + 
		(isEditing && !errMsg ? ' ReserveUsersPopup-saveReservedUserBtn--active' : '') + 
		(isEditing && isSaving ? 'ReserveUsersPopup-saveReservedUserBtn--saving' : '');

	let deleteBtnClass = 'ReserveUsersPopup-deleteReservedUserBtn' + 
		(isDeleting ? ' ReserveUsersPopup-saveReservedUserBtn--deleting' : ''); 

	// eslint-disable-next-line max-len
	const months = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];
	const days = ['Ma', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'];


	registerLocale('da', {
		localize: {
			month: (n) => {return months[n];},
			day: (n) => {return days[n];}
		}, 
		formatLong:{
			date: () => {return 'yyyy-mm-dd';}
		} 
	});



	return (
		<div className="ReserveUsersPopup">
			<div className="ReserveUsersPopup-content">
				<div className="ReserveUsersPopup-backBtn" onClick={() => {selectReservedUser(null);}} />
				<div className="ReserveUsersPopup-areaId"><span>ID: </span>{selectedReservedUser.id}</div>

				{/* Save button */}
				<div className={saveAreaBtnClass} onClick={() => {saveReservedUser();}}>Gem</div>

				{/* Delete button */}
				{selectedReservedUser.id && 
					<div className={deleteBtnClass} onClick={() => {deleteReservedUser();}}>Slet</div>}

				
				<div className="ReserveUsersPopup-error">{errMsg}</div>

				{/* User data */}
				<div className="ReserveUsersPopup-section">
					<div className="ReserveUsersPopup-input">
						<span>Brugernavn:</span>
						<input 
							type="text"
							name="name"
							value={selectedReservedUser.name ? selectedReservedUser.name : ''}
							onChange={(event) => {updateReservedUser(event);}}
						/>
					</div>
					<div className="ReserveUsersPopup-input">
						<span>Kan oprettes fra:</span>
						<DatePicker
							disabled={false}
							selected={selectedReservedUser.timeout 
								? dayjs(selectedReservedUser.timeout).toDate() : new Date()}
							onChange={(date) => {
								updateReservedUser(
									{target: 
										{
											name: 'timeout', 
											value: dayjs(date).format('YYYY-MM-DD')
										}
									}
								);
							}}
							locale={'da'}
							dateFormat="yyyy-MM-dd"
							placeholderText="yyyy-mm-dd"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};



ReserveUsersPopup.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isDeleting: PropTypes.bool.isRequired,
	selectedReservedUser: PropTypes.object.isRequired,
	errMsg: PropTypes.string,
	selectReservedUser: PropTypes.func.isRequired,
	updateReservedUser: PropTypes.func.isRequired,
	saveReservedUser: PropTypes.func.isRequired,
	deleteReservedUser: PropTypes.func.isRequired,
};

export default ReserveUsersPopup;
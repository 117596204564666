import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import Tags from './tags';

class TagsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errmsg: null,
			tags: [],
			cards: [],
			selectedTag: null,
			isEditing: false,
			isSaving: false,
			isCreating: false
		};
		this.unsubscribeTags = null;
		this.unsubscribeCards = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		Promise.all([
			this.subscribeToTags(),
			this.subscribeToCards()
		]).then(() => {
			this.setState({isLoading: false});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false, errmsg: error});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeTags) this.unsubscribeTags();
	}

	/**
	 * Subscribe to tags
	 */
	subscribeToTags = () => {
		if (this.unsubscribeTags !== null) this.unsubscribeTags();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeTags = db.collection(appConfig.tagsDbName).onSnapshot((querySnapshot) => {
				let tags = [];
				if (!querySnapshot.empty) {
					tags = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
					tags = sortArrayByProperty(tags, 'id', 'ASC');
				}
				this.setState({tags}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to cards
	 */
	subscribeToCards = () => {
		if (this.unsubscribeCards !== null) this.unsubscribeCards();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeCards = db.collection(appConfig.cardsDbName).onSnapshot((querySnapshot) => {
				let cards = [];
				if (!querySnapshot.empty) {
					cards = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						data.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
						return data;						
					});
				}
				this.setState({cards: cards}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Select tag
	 * @param {string} tagId 
	 */
	selectTag = (tagId) => {
		if (this.state.tags.some((tag) => {return tag.id === tagId;})) {
			let selectedTag = this.state.tags.find((tag) => {return tag.id === tagId;});
			this.setState({selectedTag});
		} else {
			this.setState({selectedTag: null, isEditing: false});
		}
	}

	/**
	 * Update tag
	 * @param {object} event 
	 */
	updateTag = (event) => {
		if (this.state.isSaving || this.state.isCreating) return;

		let name = event.target.name;
		let value = event.target.value;
		let selectedTag = JSON.parse(JSON.stringify(this.state.selectedTag));
		selectedTag[name] = value;
		this.setState({selectedTag, isEditing: true, errmsg: null});
	}

	/**
	 * Save changes
	 */
	saveTag = () => {
		if (this.state.isSaving || !this.state.isEditing) return;

		/* Save tag updates */
		this.setState({isSaving: true}, () => {
			let selectedTag = JSON.parse(JSON.stringify(this.state.selectedTag));
			let tagTitle = selectedTag.title.trim();
			let tagId = null;
			
			const db = firebase.firestore();
			if (selectedTag.id) {
				/* Updating existing tag */
				tagId = selectedTag.id;
				db.collection(appConfig.tagsDbName).doc(tagId).update({title: tagTitle}).then(() => {
					this.setState({isEditing: false, isSaving: false, selectedTag: null});
				}, (error) => {
					console.error(error);
					this.setState({isSaving: false});
				});
			} else {
				/* Creating new tag */
				tagId = tagTitle.toLowerCase().replace(' ', '-');
				if (this.state.tags.some((t) => {return t.id === tagId;})) {
					/* Duplicate id */
					const errorMsg = 'Cannot create tag, tag with id ' + tagId + ' already exists.';
					this.setState({isSaving: false, errmsg: errorMsg});
				} else {
					db.collection(appConfig.tagsDbName).doc(tagId).set({title: tagTitle}).then(() => {
						this.setState({isEditing: false, isSaving: false, selectedTag: null});
					}, (error) => {
						console.error(error);
						this.setState({isSaving: false});
					});
				}
			}
		});
	}

	/**
	 * Create new tag
	 */
	createNewTag = () => {
		if (this.state.isSaving || this.state.isCreating) return;
		const newTag = {id: null, title: ''};
		this.setState({selectedTag: newTag});
	}

	deleteTag = () => {
		if (this.state.isSaving || this.state.isCreating) return;
		const db = firebase.firestore();

		db.collection(appConfig.tagsDbName).doc(this.state.selectedTag.id).delete().then(() => {
			this.setState({isEditing: false, isSaving: false, selectedTag: null});
		}, (error) => {
			console.error(error);
			this.setState({isSaving: false});
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		return (
			<Tags 
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				isCreating={this.state.isCreating}
				tags={this.state.tags}
				cards={this.state.cards}
				selectedTag={this.state.selectedTag}
				selectTag={this.selectTag}
				updateTag={this.updateTag}
				saveTag={this.saveTag}
				createNewTag={this.createNewTag}
				deleteTag={this.deleteTag}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

TagsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default TagsController;
import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import Areas from './areas';

class AreasController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errmsg: null,
			areas: [],
			selectedArea: null,
			isEditing: false,
			isSaving: false,
			isCreating: false
		};
		this.unsubscribeAreas = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		this.subscribeToAreas().then(() => {
			this.setState({isLoading: false});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false, errmsg: error});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeAreas) this.unsubscribeAreas();
	}

	/**
	 * Subscribe to areas
	 */
	subscribeToAreas = () => {
		if (this.unsubscribeAreas !== null) this.unsubscribeAreas();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeAreas = db.collection(appConfig.areasDbName).onSnapshot((querySnapshot) => {
				let areas = [];
				if (!querySnapshot.empty) {
					areas = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = parseInt(doc.id);
						return data;						
					});
					areas = sortArrayByProperty(areas, 'id', 'ASC');
				}
				this.setState({areas}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Select area
	 * @param {string} areaId 
	 */
	selectArea = (areaId) => {
		if (this.state.areas.some((area) => {return area.id === areaId;})) {
			let selectedArea = this.state.areas.find((area) => {return area.id === areaId;});
			this.setState({selectedArea});
		} else {
			this.setState({selectedArea: null, isEditing: false});
		}
	}

	/**
	 * Update area
	 * @param {object} event 
	 */
	updateArea = (event, obj = null) => {
		if (this.state.isSaving || this.state.isCreating) return;
		let name = event.target.name;
		let value = event.target.value;
		let selectedArea = JSON.parse(JSON.stringify(this.state.selectedArea));
		if (!obj) {
			selectedArea[name] = value;
		} else {
			
			if (name === 'nextAreaId' && value < 0) {
				selectedArea[obj] = null;
			} else {
				if (!selectedArea.hasOwnProperty(obj) || !selectedArea[obj]) {
					selectedArea[obj] = {[name] : value, keys: 3};
				} else {
					if (name === 'nextAreaId' && value === 'exit') {
						selectedArea[obj][name] = value;
					} else {
						selectedArea[obj][name] = parseInt(value);
					}
					
				}
			}
		}
		
		this.setState({selectedArea, isEditing: true});
	}

	/**
	 * Save changes
	 */
	saveArea = () => {
		/* Save area updates */
		if (this.state.isEditing && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {
				let portal = JSON.parse(JSON.stringify(this.state.selectedArea.portal));
				if (portal !== null && portal.keys < 1) portal.keys = 1;
				let areaUpdates = {
					title: this.state.selectedArea.title, 
					imageFile: this.state.selectedArea.imageFile, 
					portal
				};
				const db = firebase.firestore();
				let areaId = this.state.selectedArea.id.toString();
				db.collection(appConfig.areasDbName).doc(areaId)
					.update(areaUpdates).then(() => {
						this.setState({isEditing: false, isSaving: false, selectedArea: null});
					}, (error) => {
						console.error(error);
						this.setState({isSaving: false});
					});
			});
		}
	}

	/**
	 * Create new area
	 */
	createNewArea = () => {
		if (this.state.isSaving || this.state.isCreating) return;

		this.setState({isCreating: true}, () => {
			/* Generate area id */
			let areaId = this.state.areas.length + 1;

			/* Check area id does not already exist */
			let areaIdExists = this.state.areas.some((area) => {return area.id === areaId;});;
			let numberOfIterations = 0;
			while (areaIdExists && numberOfIterations < 1000) {
				numberOfIterations += 1;
				areaId = areaId + 1;
				// eslint-disable-next-line no-loop-func
				areaIdExists = this.state.areas.some((area) => {return area.id === areaId;});
			}

			if (!areaIdExists) {
				/* Create new area */
				let newAreaObj = {title: 'Area ' + areaId, imageFile: 'maze-1.svg', portal: null};
				let areaRefId = areaId.toString();
				const db = firebase.firestore();
				db.collection(appConfig.areasDbName).doc(areaRefId).set(newAreaObj).then(() => {
					this.setState({isCreating: false});
				}, (error) => {
					console.error(error);
					this.setState({isCreating: false});
				});
			} else {
				console.error('Error creating area');
			}
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		return (
			<Areas 
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				isCreating={this.state.isCreating}
				areas={this.state.areas}
				selectedArea={this.state.selectedArea}
				selectArea={this.selectArea}
				updateArea={this.updateArea}
				saveArea={this.saveArea}
				createNewArea={this.createNewArea}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

AreasController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default AreasController;
import React from 'react';
import PropTypes from 'prop-types';
import {useSpring, animated, interpolate} from 'react-spring';
import {useGesture} from 'react-use-gesture';
import {renderMarkdown} from 'helpers/text-helper';
import './card.scss';

const Card = ({cardData, selectedOption, cardDirection, setCardDirection, handleSelectOption}) => {
	let windowWidth = window.innerWidth;
	let displayAnswerTextLimit = 20;
	let maxDeltaX = windowWidth / 2.5; // 4

	/* Handle intro feedback */
	let feedback = null;
	if (cardData.deckId === 'intro') feedback = cardData.text;

	/* Create flip spring */
	const {opacity, rotY} = useSpring({
		opacity: 1,
		rotY: 0,
		delay: 250,
		from: {opacity: 0, rotY: 180},
		config: { mass: 5, tension: 500, friction: 80 }
	});
	
	/* Create swipe spring */
	const [springProps, set] = useSpring(() => {
		return {x: 0, rot: 0, y: 0};
	});

	/* Create gesture */
	const bind = useGesture(({ down, delta: [xDelta], direction: [xDir], velocity, cancel }) => {
		/* Direction / position should either point left (-1) or right (+1) */
		const dir = xDir < 0 ? -1 : 1;
		const pos = xDelta < 0 ? -1 : 1;

		/* User selected an option */
		const triggerSelect = 
			(velocity > 1 && dir === pos) ||  // 0.3
			(velocity < 1 && Math.abs(xDelta) > windowWidth / 3.) // 5
		;
		
		if (!down && triggerSelect) {
			set(() => {
				return {x: windowWidth * 0.5 * dir, y: 300, rot: dir * 90, config: { friction: 10, tension: 100}};
			});
			let optionData = cardData['answer' + (cardDirection === 'left' ? 'A' : 'B')];
			if (selectedOption) optionData = selectedOption;
			handleSelectOption(optionData);
			setTimeout(() => {setCardDirection('');}, 200);
		} else {
			/* User considers left / right */
			let leaning = '';
			if (down && xDelta < -displayAnswerTextLimit) leaning = 'left';
			if (down && xDelta > displayAnswerTextLimit) leaning = 'right';
			setCardDirection(leaning);

			set(() => {
				let x = (down ? xDelta : 0);
				let rot = (down ? xDelta / 10. : 0);
				let friction = (down ? 100 : 15);
				let tension = (down ? 0 : 200);
					
				if (Math.abs(x) > maxDeltaX && down) {
					x = (x / Math.abs(x)) * maxDeltaX;
					rot = x / 10.;
				}

				return { x: x, y: 0, rot: rot, config: { friction: friction, tension: tension}};
			});
		}
	});

	let backgroundImage = null;
	if (cardData.imageUrl) {
		backgroundImage = 'url("' + cardData.imageUrl + '")';
	}
	if (cardData.deckId !== 'intro' && feedback) {
		let cardImg = require('../../../assets/images/cards/feedback.svg').default;
		backgroundImage = 'url("' + cardImg + '")';
	}

	let fontSize = 16;
	let el = document.getElementById('app');
	if (el) {
		let style = window.getComputedStyle(el, null).getPropertyValue('font-size');
		fontSize = parseFloat(style); 
	}

	return (
		<animated.div
			className="Card"
			{...bind()}
			style={{
				transform: interpolate([springProps.x, springProps.y], 
					(x, y) => {return `translate3d(${x}px,${y}px,0)`;})
			}}>
			<animated.div 
				className={'Card-content Card-content--' + cardData.id}
				style={{
					transform: springProps.rot.interpolate((rot) => {return `rotate(${rot}deg)`;}),
				}}
			>
				<animated.div 
					className="Card-back" 
					style={{ 
						opacity: opacity.interpolate((o) => {return 1 - o;}), 
						transform: rotY.interpolate((rotY) => {return `perspective(600px) rotateY(${180 - rotY}deg)`;}) 
					}} 
				/>
				<animated.div
					className={'Card-front' + 
						(feedback ? ' Card-front--feedback' : '') + 
						(cardData.deckId === 'intro' ? ' Card-front--intro' : '')
					} 
					style={{
						opacity: opacity.interpolate((o) => {return o;}), 
						transform: rotY.interpolate((rotY) => {return `perspective(600px) rotateY(${-rotY}deg)`;}),
						backgroundImage: backgroundImage
					}}
				>
					{feedback && renderMarkdown(feedback)}
					{(
						!selectedOption && 
						(
							(cardData.answerA && cardData.answerA.text && cardData.answerA.text.length > 0) ||
							(cardData.answerB && cardData.answerB.text && cardData.answerB.text.length > 0)
						)
					) && 
						<animated.div 
							className={'Card-answer' + 
								(cardDirection.length > 0 ? ' Card-answer--' + cardDirection : '')}
							style={{
								transform: springProps.rot.interpolate((rot) => {return `rotate(${-1 * rot}deg)`;}),
								paddingTop: springProps.x.interpolate((x) => {
									if (Math.abs(x) < displayAnswerTextLimit) return '0px';
									return `${(Math.abs(x) / 2) - 20 + (1.5 * fontSize)}px`;
								}),
							}}
						>
							{cardDirection === 'left' && 
								(cardData.answerA && cardData.answerA.text ? cardData.answerA.text : '')}
							{cardDirection === 'right' && 
								(cardData.answerB && cardData.answerB.text ? cardData.answerB.text : '')}
						</animated.div>}
				</animated.div>
			</animated.div>
		</animated.div>
			
		
	);
};

Card.propTypes = {
	cardData: PropTypes.object.isRequired,
	selectedOption: PropTypes.object,
	cardDirection: PropTypes.string.isRequired,
	setCardDirection: PropTypes.func.isRequired,
	handleSelectOption: PropTypes.func.isRequired,
};

export default Card;
import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import CardFeedback from 'components/ui/card/card-feedback';
import './portal-feedback.scss';

const PortalFeedback = ({portalIsOpen, handleClosePortalPopup}) => {
	let cardData = {
		text: (portalIsOpen ? generalUiTexts.portalOpened : generalUiTexts.lockOpened),
	};
	return (
		<div className="PortalFeedback">
			<div className="PortalFeedback-cardContainer">
				<CardFeedback 
					feedbackType="portal"
					cardData={cardData}
					goToNextGameStep={() => {handleClosePortalPopup(portalIsOpen);}}
				/>
			</div>
		</div>				
	);
};

PortalFeedback.propTypes = {
	portalIsOpen: PropTypes.bool.isRequired,
	handleClosePortalPopup: PropTypes.func.isRequired
};

export default PortalFeedback;
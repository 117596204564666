import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import appConfig from 'config/app.config';

/**
 * Get image url
 * @param {*} path 
 */
function getImageUrl(location, fileName) {
	return new Promise((resolve) => {
		let storageRef = firebase.storage().ref();
		storageRef.child(location + '/' + fileName).getDownloadURL()
			.then((url) => {resolve({fileName: fileName, url: url});})
			.catch((error) => {resolve(error);});
	});
}

/**
 * Save image
 * @param {string} location
 * @param {array} images 
 */
function saveImages(location, images) {
	return new Promise((resolve, reject) => {
		let storageRef = firebase.storage().ref();
		let promises = [];
		
		for (let i = 0; i < images.length; i++) {
			let imgRef = storageRef.child(location + '/' + images[i].name);
			promises.push(imgRef.put(images[i]));
		}

		Promise.all(promises).then((responses) => {
			let failedUploads = [];
			let promises = [];
			let db = firebase.firestore();
			responses.forEach((response, index) => {
				if (response.state === 'success') {
					let imgId = images[index].name.split('.')[0];
					let imgRef = db.collection(appConfig.imagesDbName).doc(imgId);
					promises.push(imgRef.set({fileName: images[index].name, location: location}));
				} else {
					failedUploads.push(images[index].name);
				}
			});

			Promise.all(promises).then(() => {
				resolve({status: 'ok', failedUploads});
			}, (error) => {
				console.error(error);
				reject({status: 'error', error: error});
			});
		});
	});
}

/**
 * Delete image
 * @param {string} location 
 * @param {string} fileName 
 * @param {string} imageId 
 */
function deleteImage(location, fileName, imageId) {
	return new Promise((resolve, reject) => {
		let storageRef = firebase.storage().ref();
		let imgRef = storageRef.child(location + '/' + fileName);
		imgRef.delete().then(() => {
			let db = firebase.firestore();
			db.collection(appConfig.imagesDbName).doc(imageId).delete().then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				resolve({status: 'error', error: error});
			});
		}).catch(function(error) {
			reject(error);
		});
	});
}


export {
	getImageUrl,
	saveImages,
	deleteImage
};
import React from 'react';
import PropTypes from 'prop-types';
import SurveyPreview from './survey-preview';
import SurveyPopup from './survey-popup';
import './surveys.scss';

const Surveys = (props) => {
	let {
		isEditing, 
		isSaving,
		isCreating,
		isDeleting,
		surveys,
		cards,
		images,
		tags,
		selectedSurvey, 
		surveyPreviewId,
		selectSurvey, 
		updateSurvey, 
		saveSurvey,
		showSurveyPreview,
		createNewSurvey,
		createNewStep,
		deleteStep,
		goToPage,
	} = props;


	// let card = selectedSurvey;
	// if (card) {
	// 	let imageUrl = null;
	// 	if (survey.imageFile) {
	// 		if (images.some((cardImage) => {
	// 			return (cardImage.fileName === survey.imageFile && cardImage.url);
	// 		})) {
	// 			imageUrl = images.filter((cardImage) => {
	// 				return cardImage.fileName === survey.imageFile;
	// 			})[0].url;
	// 		}
	// 	}
	// 	survey.imageUrl = imageUrl;
	// }

	return (
		<div className="Surveys">
			<div className="Surveys-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>


			{/* Surveys overview */}
			<div className="Surveys-section">
				<div className="Surveys-title">Surveys:</div>
				<div className="Surveys-header">
					<div className="Surveys-surveyPreview" />
					<div className="Surveys-surveyId">Id</div>
					<div className="Surveys-surveyMazeId">Maze</div>
					<div className="Surveys-surveyTitle">Titel</div>
					<div className="Surveys-surveyType">Type</div>
					<div className="Surveys-surveyCards">Kort</div>
					<div className="Surveys-surveySteps">Dele</div>
				</div>
				{surveys.map((survey, index) => {
					let cardsTriggeringSurvey = cards.filter((card) => {
						return (
							(card.hasOwnProperty('answerA') && 
							card.answerA && card.answerA.hasOwnProperty('survey') && 
							card.answerA.survey && 
							card.answerA.survey.surveyType === survey.surveyType) ||
							(card.hasOwnProperty('answerB') && 
							card.answerB && card.answerB.hasOwnProperty('survey') && 
							card.answerB.survey && 
							card.answerB.survey.surveyType === survey.surveyType)
						);
					});
					return (
						<div 
							key={index} 
							className="Surveys-row" 
							onClick={() => {if (!isSaving) selectSurvey(survey.id);}}
						>
							<div 
								className="Surveys-surveyPreview" 
								onClick={(event) => {event.stopPropagation(); showSurveyPreview(survey.id);}}
							/>
							<div className="Surveys-surveyId">{survey.id}</div>
							<div className="Surveys-surveyMazeId">{survey.mazeId ? survey.mazeId : '-'}</div>
							<div className="Surveys-surveyTitle">{survey.title}</div>
							<div className="Surveys-surveyType">{survey.surveyType}</div>
							<div className="Surveys-surveyCards">
								{cardsTriggeringSurvey.map((card, index) => {
									let options = '';
									if (
										card.hasOwnProperty('answerA') && 
										card.answerA && 
										card.answerA.hasOwnProperty('survey') && 
										card.answerA.survey && 
										card.answerA.survey.surveyType === survey.surveyType
									) {
										options = ' (A)';
									}
									if (
										card.hasOwnProperty('answerB') && 
										card.answerB && 
										card.answerB.hasOwnProperty('survey') && 
										card.answerB.survey && 
										card.answerB.survey.surveyType === survey.surveyType
									) {
										if (options.length === 0) {
											options = '(B)';
										} else {
											options = '(A+B)';
										}
									}
									if (index + 1 < cardsTriggeringSurvey.length) options = options + ', ';
									return <span key={index}>{card.id} {options}</span>;
								})}

							</div>
							<div className="Surveys-surveySteps">{survey.steps.length}</div>
						</div>
					);
				})}
				<div 
					className={'Surveys-newSurveyBtn' + (isCreating ? ' Surveys-newSurveyBtn--saving' : '') } 
					onClick={() => {createNewSurvey();}}
				>Opret ny survey</div>
			</div>

			{/* Edit survey popup */}
			{(selectedSurvey && !surveyPreviewId) &&
				<SurveyPopup 
					isEditing={isEditing}
					isSaving={isSaving}
					isCreating={isCreating}
					isDeleting={isDeleting}
					survey={selectedSurvey} 
					surveys={surveys}
					cards={cards}
					images={images}
					tags={tags}
					updateSurvey={updateSurvey} 
					selectSurvey={selectSurvey}
					createNewStep={createNewStep}
					deleteStep={deleteStep}
					saveSurvey={saveSurvey}
				/>
			}

			{/* Survey preview */}
			{surveyPreviewId && 
				<SurveyPreview 
					type="popup"
					surveyData={selectedSurvey} 
					showSurveyPreview={showSurveyPreview}
				/>}
		</div>
	);
};

Surveys.defaultProps = {
	selectedSurvey: null
};

Surveys.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	isDeleting: PropTypes.bool.isRequired,
	surveys: PropTypes.array.isRequired,
	cards: PropTypes.array.isRequired,
	images: PropTypes.array.isRequired,
	tags: PropTypes.array.isRequired,
	selectedSurvey: PropTypes.object,
	surveyPreviewId: PropTypes.string,
	selectSurvey: PropTypes.func.isRequired,
	updateSurvey: PropTypes.func.isRequired,
	saveSurvey: PropTypes.func.isRequired,
	showSurveyPreview: PropTypes.func.isRequired,
	createNewSurvey: PropTypes.func.isRequired,
	createNewStep: PropTypes.func.isRequired,
	deleteStep: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Surveys;
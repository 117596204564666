/**
 * Get current date (yyyy-mm-dd)
 */
function getCurrentDate() {
	let d = new Date();
	let currentDate = d.getFullYear() + '-' 
		+ ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1) + '-'
		+ (d.getDate() < 10 ? '0' : '') + d.getDate();

	return currentDate;
}

export {getCurrentDate};
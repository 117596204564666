import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import PortalMini from 'components/portal-mini/portal-mini';
import './progress.scss';

const Progress = ({hideProgress, isFreeplay, keys, unlockedKeys}) => {
	let gameDuration = appConfig.gameDurationLimitSecs;
	let sunDurationSecs = Math.floor((gameDuration / 3.) * 2);
	let moonDurationSecs = (gameDuration - sunDurationSecs);

	return (
		<div 
			className={'Progress' + (hideProgress ? ' Progress--hidden' : '')}
			style={{
				WebkitAnimationDelay: sunDurationSecs + 's',
				animationDelay: sunDurationSecs + 's'
			}}
		>
			<div 
				className="Progress-satellite Progress-satellite--sun"
				style={{
					WebkitAnimationDuration: sunDurationSecs + 's', 
					animationDuration: sunDurationSecs + 's'
				}}
			/>
			<div 
				className="Progress-satellite Progress-satellite--moon"
				style={{
					WebkitAnimationDuration: moonDurationSecs + 's',
					animationDuration: moonDurationSecs + 's', 
					WebkitAnimationDelay: sunDurationSecs + 's',
					animationDelay: sunDurationSecs + 's'
				}}
			/>
			<div className="Progress-maze" />
			{!isFreeplay && <PortalMini type="progress" keys={keys} unlockedKeys={unlockedKeys} />}
		</div>
	);
};

Progress.propTypes = {
	hideProgress: PropTypes.bool.isRequired,
	isFreeplay: PropTypes.bool.isRequired,
	keys: PropTypes.number.isRequired,
	unlockedKeys: PropTypes.number.isRequired
};

export default Progress;
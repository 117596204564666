import React from 'react';
import marked from 'marked';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
function renderMarkdown(markdown) {
	if (typeof markdown === 'string' && markdown.length > 0) {
		let lexed = marked.lexer(markdown);
		let parsed = marked.parser(lexed);
		return <div dangerouslySetInnerHTML={{__html: parsed}} />;
	}
	return <div />;
}

export {renderMarkdown};
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MainMenu from 'components/main-menu/main-menu';
import IntroController from 'components/intro/intro-controller';
import MazeGameController from 'components/maze-game/maze-game-controller';

const gamePages = {
	menu: {component: MainMenu},
	intro: {component: IntroController},
	mazeGame: {component: MazeGameController},
};

const Game = (props) => {
	const {
		playerData, 
		areasData, 
		decksData, 
		missionsData,
		surveysData, 
		cardImages, 
		updatePlayerData
	} = props;

	/* Page, animations, game type */
	const [pageId, setPageId] = useState('menu');
	const [animateSlideIn, setAnimateSlideIn] = useState(true);

	/**
	 * Show page
	 * @param {string} pageId 
	 */
	const handleShowPage = (pageId, param = false) => {
		setAnimateSlideIn(false);
		setPageId(pageId);
	};


	/* Get game component */
	let Component = MainMenu;
	if (gamePages.hasOwnProperty(pageId)) Component = gamePages[pageId].component;
		
	return (
		<Component
			animateSlideIn={animateSlideIn}
			missionId={null}
			isPlaying={false}
			isGameover={false}
			playerData={playerData}
			decksData={decksData}
			areasData={areasData}
			missionsData={missionsData}
			surveysData={surveysData}
			cardImages={cardImages}
			handleShowPage={handleShowPage}
			updatePlayerData={updatePlayerData}
		/>
	);
};


Game.propTypes = {
	playerData: PropTypes.object.isRequired,
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	cardImages: PropTypes.array.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
};

export default Game;
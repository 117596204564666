import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
// import statsData from 'data/stats-data';
import Select from 'components/ui/select/select';
import DeckPopup from './deck-popup';
import CardPreview from './card-preview';
import CardPopup from './card-popup';
import './cards.scss';

const Cards = (props) => {
	let {
		isEditingCard, 
		isSaving,
		isCreatingCard,
		isCreatingDeck,
		showDeckPopup,
		areas,
		decks,
		cards,
		missions,
		images,
		cardTypesData,
		tags,
		surveys,
		deckId, 
		cardsInDeck, 
		selectedCard, 
		cardPreviewId,
		toggleCardStatus,
		toggleDeckPopup,
		toggleArea,
		toggleType,
		selectDeck, 
		selectCard,
		updateCard, 
		saveCard,
		showCardPreview,
		createNewDeck,
		createNewCard,
		deleteDeck,
		goToPage,
	} = props;

	let answers = ['answerA', 'answerB'];

	let card = selectedCard;
	if (card) {
		let imageUrl = null;
		if (card.imageFile) {
			if (images.some((cardImage) => {
				return (cardImage.fileName === card.imageFile && cardImage.url);
			})) {
				imageUrl = images.filter((cardImage) => {
					return cardImage.fileName === card.imageFile;
				})[0].url;
			}
		}
		card.imageUrl = imageUrl;
	}

	return (
		<div className="Cards">
			<div className="Cards-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>


			
			<div className="Cards-section">
				<div className="Cards-title">Dæk:</div>
				{/* Create deck popup btn */}
				<div className="Cards-newDeckBtn" onClick={() => {toggleDeckPopup(true);}}>Opret nyt dæk</div><br />
				{/* Select deck */}
				<Select 
					type="deckId"
					options={decks} 
					defaultValue={'Vælg dæk'} 
					selectedId={deckId ? deckId : ''}
					onSelect={selectDeck}
				/>
			</div>

			{/* Cards overview */}
			{deckId && <div className={'Cards-section ' + deckId}>
				<div className="Cards-title">Kort:</div>
				{deckId === 'basic' && <div className="Cards-types">
					{cardTypesData.map((type, index) => {
						return (
							<div key={index} className="Cards-type">
								<input 
									type="checkbox" 
									checked={type.show} 
									onChange={() => {toggleType(index);}} 
								/>
								{type.title}
							</div>
						);
					})}
				</div>}
				{deckId === 'basic' && <div className="Cards-areas">
					{areas.map((area, index) => {
						return (
							<div key={index} className="Cards-area">
								<input 
									type="checkbox" 
									checked={area.show} 
									onChange={() => {toggleArea(index);}} 
								/>
								{area.title}
							</div>
						);
					})}
				</div>}
				<div className="Cards-header">
					<div className="Cards-cardPreview" />
					<div className="Cards-cardToggleActive">Active</div>
					<div className="Cards-cardId">Id</div>
					{deckId === 'basic' && <div className="Cards-cardMazeId">Area</div>}
					<div className="Cards-cardTitle">Titel</div>
					<div className="Cards-cardType">Type</div>
					<div className="Cards-cardImage">Billede</div>
					<div className="Cards-cardText">Tekst</div>
					<div className="Cards-cardOption">A</div>
					<div className="Cards-cardOption">B</div>
				</div>
				{cardsInDeck.map((card, index) => {
					let areaId = (card.mazeId ? card.mazeId : 1);
					let areaData = areas.find((area) => {return area.id === areaId;});
					let cardTypeData = cardTypesData.find((type) => {return type.value === card.isRandom;});
					let showCard = false;
					if (areaData || cardTypeData) {
						showCard = ((!areaData || areaData.show) && (!cardTypeData || cardTypeData.show));
					}
					if (!showCard && deckId === 'basic') return null;
					return (
						<div key={index} className="Cards-card" onClick={() => {if (!isSaving) selectCard(card.id);}}>
							<div className="Cards-cardPreview" 
								onClick={(event) => {event.stopPropagation(); showCardPreview(card.id);}}
							/>
							<div 
								className={'Cards-cardToggleActive' + 
									(card.isRandom ? ' random' : '') + (card.isDisabled ? ' disabled' : '')} 
								onClick={(event) => {
									event.stopPropagation();
									if (card.isRandom) toggleCardStatus(card.id, (card.isDisabled ? false : true));
								}}
							/>
							<div className="Cards-cardId">{card.id}</div>
							{deckId === 'basic' && 
								<div className="Cards-cardMazeId">{
									card.mazeId 
										? card.mazeId + (card.mazeIdMax ? '-' + card.mazeIdMax : '')
										: '-'
								}</div>}
							<div className="Cards-cardTitle">{card.title}</div>
							<div className="Cards-cardType">{(card.isRandom === false ? 'P' : 'R')}</div>
							<div className="Cards-cardImage">
								{(card.imageFile && card.imageFile.length > 0 ? card.imageFile : '')}
							</div>
							<div className="Cards-cardText">{renderMarkdown(card.text)} </div>
													
							{answers.map((answer, index) => {
								if (card.hasOwnProperty(answer)) {
									let hasStats = false;
									// statsData.stats.forEach((stat) => {
									// 	if (card[answer].stats.hasOwnProperty(stat.id) && 
									// 		card[answer].stats[stat.id] !== 0) hasStats = true;
									// });
									let hasFeedback = (card[answer].hasOwnProperty('feedback') && 
										card[answer].feedback && card[answer].feedback !== null);
									let hasSecret = (card[answer].hasOwnProperty('secretId') && 
										card[answer].secretId !== null);
									let hasMission = (card[answer].hasOwnProperty('mission') && 
										card[answer].mission !== null);
									let hasSurvey = (card[answer].hasOwnProperty('survey') && 
									card[answer].survey !== null);
									return (
										<div key={index} className="Cards-cardOption">
											<div className="Cards-optionText">{card[answer].text}</div>
											<div className="Cards-optionEffects">
												{(hasFeedback || hasStats || hasSecret || hasMission || hasSurvey) &&
												<span>({hasFeedback && 'F'}{hasStats && 'E'}
													{hasSecret && 'H'}{hasMission && 'M'}
													{hasSurvey && 'S'})
												</span>
												}
											</div>
											<div className="Cards-cardNext">
												{card[answer].nextCard.type === 'random' && 
													card[answer].nextCard.deckId}
												{card[answer].nextCard.type === 'specific' && 
													card[answer].nextCard.cardId}
											</div>
										</div>
									);
								}
								return null;
							})}

							{(card.hasOwnProperty('button') && card.button && card.button.nextCard) &&
								<div key={index} className="Cards-cardOption">
									<div className="Cards-cardNext">
										{card.button.nextCard.type === 'random' && 
											card.button.nextCard.deckId}
										{card.button.nextCard.type === 'specific' && 
											card.button.nextCard.cardId}
										{card.button.nextCard.type === 'mazeGame' && 
											'start spil'}
									</div>
								</div>
							}
						</div>
					);
				})}
				<div 
					className={'Cards-newCardBtn' + (isCreatingCard ? ' Cards-newCardBtn--saving' : '') } 
					onClick={() => {createNewCard(deckId);}}
				>Opret nyt kort</div>
			</div>}

			{/* Edit card popup */}
			{(card && !cardPreviewId) &&
				<CardPopup 
					isEditingCard={isEditingCard}
					isSaving={isSaving}
					card={card} 
					decks={decks}
					cards={cards}
					missions={missions}
					images={images}
					tags={tags}
					surveys={surveys}
					updateCard={updateCard} 
					selectCard={selectCard}
					saveCard={saveCard}
				/>
			}

			{/* Card preview */}
			{cardPreviewId && 
				<CardPreview 
					type="popup"
					cardData={card} 
					showCardPreview={showCardPreview}
				/>}

			{/* Deck popup */}
			{showDeckPopup && 
				<DeckPopup
					isCreatingDeck={isCreatingDeck}
					decks={decks}	
					cards={cards}
					toggleDeckPopup={toggleDeckPopup}
					createNewDeck={createNewDeck}
					deleteDeck={deleteDeck}
				/>}
		</div>
	);
};

Cards.defaultProps = {
	selectedCard: null
};

Cards.propTypes = {
	isEditingCard: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isCreatingCard: PropTypes.bool.isRequired,
	isCreatingDeck: PropTypes.bool.isRequired,
	showDeckPopup: PropTypes.bool.isRequired,
	areas: PropTypes.array.isRequired,
	decks: PropTypes.array.isRequired,
	cards: PropTypes.array.isRequired,
	missions: PropTypes.array.isRequired,
	images: PropTypes.array.isRequired,
	cardTypesData: PropTypes.array.isRequired,
	tags: PropTypes.array.isRequired,
	surveys: PropTypes.array.isRequired,
	deckId: PropTypes.string,
	cardsInDeck: PropTypes.array.isRequired,
	selectedCard: PropTypes.object,
	cardPreviewId: PropTypes.string,
	toggleCardStatus: PropTypes.func.isRequired,
	toggleDeckPopup: PropTypes.func.isRequired,
	toggleArea: PropTypes.func.isRequired,
	toggleType: PropTypes.func.isRequired,
	selectDeck: PropTypes.func.isRequired,
	selectCard: PropTypes.func.isRequired,
	updateCard: PropTypes.func.isRequired,
	saveCard: PropTypes.func.isRequired,
	showCardPreview: PropTypes.func.isRequired,
	createNewDeck: PropTypes.func.isRequired,
	createNewCard: PropTypes.func.isRequired,
	deleteDeck: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Cards;
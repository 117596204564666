import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {surveyTypes, stepTypes, yesNoOptions} from 'data/surveys-data';
import Select from 'components/ui/select/select';
import SurveyPreview from './survey-preview';
import './survey-popup.scss';

const SurveyPopup = (props) => {
	let {
		isCreating, 
		isEditing, 
		isDeleting,
		isSaving, 
		survey, 
		images, 
		tags,
		updateSurvey, 
		selectSurvey, 
		createNewStep, 
		deleteStep,
		saveSurvey
	} = props;

	let [showSurveyPreview, setShowSurveyPreview] = useState(false);

	let saveSurveyBtnClass = 'SurveyPopup-saveSurveyBtn' + 
	(isEditing ? ' SurveyPopup-saveSurveyBtn--active' : '') + 
	(isEditing && isSaving ? ' SurveyPopup-saveSurveyBtn--saving' : '');

	let createStepBtnClass = 'SurveysPopup-newStepBtn' +
		(!isEditing && !isSaving ? ' SurveysPopup-newStepBtn--active' : '') +
		(isCreating ? ' SurveysPopup-newStepBtn--saving' : '');
		
	let availableSurveyImages = [''].concat(images);
	let availableSurveyTypes = JSON.parse(JSON.stringify(surveyTypes));
	let surveyTypeIsFromTag1 = false;
	// let surveyTypeIsFromTag2 = false;
	tags.forEach((tag) => {
		if (survey.surveyType === 'tag-' + tag.id) surveyTypeIsFromTag1 = true;
		availableSurveyTypes.push(
			{id: 'tag-' + tag.id, value: 'tag-' + tag.id, title: 'Tag prompt 1 (' + tag.title + ')'}
		);

		// if (survey.surveyType === 'tag2-' + tag.id) surveyTypeIsFromTag2 = true;
		availableSurveyTypes.push(
			{id: 'tag2-' + tag.id, value: 'tag2-' + tag.id, title: 'Tag prompt 2 (' + tag.title + ')'}
		);
	});

	return (
		<div className="SurveyPopup">
			<div className="SurveyPopup-content">
				<div className="SurveyPopup-backBtn" onClick={() => {selectSurvey(null);}} />
				<div className="SurveyPopup-surveyId"><span>ID: </span>{survey.id}</div>

				{/* Save button */}
				<div className={saveSurveyBtnClass} onClick={() => {saveSurvey();}}>Gem</div>
				<div className="SurveyPopup-previewBtn" onClick={() => {setShowSurveyPreview(true);}}>Preview</div>
				
				{/* Survey data */}
				<div className="SurveyPopup-section SurveyPopup-section--survey">
					<div className="SurveyPopup-subsection">
						<div className="SurveyPopup-surveyInfo">
							<span>Titel:</span>
							<input 
								type="text"
								name="title"
								value={survey.title}
								onChange={(event) => {updateSurvey(event);}}
							/>
						</div>
						<div className="SurveyPopup-surveyInfo">
							<span>Type:</span>
							<Select 
								type={'surveyType'} 
								options={availableSurveyTypes} 
								defaultValue={'gameover'} 
								selectedId={survey.surveyType}
								onSelect={updateSurvey}
							/>
						</div>
						<div className="SurveyPopup-surveyInfo">
							<span>Maze:</span>
							<input 
								type="number"
								name="mazeId"
								value={survey.mazeId ? survey.mazeId : ''}
								onChange={(event) => {updateSurvey(event);}}
							/>
						</div>
					</div>
				</div>
				<hr />

				{/* Survey steps */}
				<div className="SurveyPopup-section">
					{survey.steps.map((step, stepIndex) => {
						const numberOfOptions = (step.options && step.options.length > 0
							? step.options.filter((o) => {return (typeof o === 'object');}).length
							: 0
						);
						return (
							<div key={stepIndex} className="SurveyPopup-subSection">
								{survey.steps.length > 1 && 
									<div 
										className={'SurveyPopup-deleteStepBtn' + (isDeleting ? ' deleting' : '')} 
										onClick={() => {deleteStep(stepIndex);}}
									/>
								}
								<div className="SurveyPopup-sectionTitle">Del {stepIndex + 1}</div>
								<div className="SurveyPopup-surveyInfo">
									<span>Type:</span>
									<Select 
										type={'type'} 
										extraParams={[stepIndex]}
										options={stepTypes} 
										defaultValue={'intro'} 
										selectedId={step.type}
										onSelect={updateSurvey}
									/>
								</div>
								{step.type === 'intro' && <div className="SurveyPopup-surveyInfo">
									<span>Titel:</span>
									<input 
										type="text"
										name="title"
										value={step.title ? step.title : ''}
										onChange={(event) => {updateSurvey(event, stepIndex);}}
									/>
								</div>}
								{step.type === 'intro' && <div className="SurveyPopup-surveyInfo">
									<span>Billede:</span>
									<Select
										type="image"
										extraParams={[stepIndex]}
										options={availableSurveyImages}
										defaultValue=''
										selectedId={(step.image ? step.image : '')}
										onSelect={updateSurvey}
									/>
								</div>}
								<div className="SurveyPopup-surveyInfo">
									<span>Tekst:</span>
									<textarea 
										name="text"
										value={(step.text ? step.text : '')}
										placeholder=""
										onChange={(event) => {updateSurvey(event, stepIndex);}}
									/>
								</div>
								{(step.type === 'multiple-choice' || step.type === 'tag-mc' ) && 
									<div className={'SurveyPopup-surveyInfo mc' 
										+ (numberOfOptions > 7 ? ' layout2' : ' layout1')}
									>
										<span>Valgmuligheder:</span><br />
										{[...Array(9)].map((_, optionIndex) => {
											return (
												<input 
													key={optionIndex}
													type="text"
													name="options"
													value={(
														step.options && step.options.length > optionIndex &&
														typeof step.options[optionIndex] === 'object'
															? step.options[optionIndex].title : '')}
													placeholder={optionIndex + 1}
													onChange={(event) => {updateSurvey(event, stepIndex, optionIndex);}}
												/>
											);
										})}
									</div>}
								{step.type === 'tag-choose' && <div className="SurveyPopup-surveyInfo">
									<span>Valgmuligheder:</span><br />
									{yesNoOptions.map((option) => {
										return (
											<input 
												key={option.id}
												type="text"
												name="options2"
												value={option.title}
												disabled={true}
											/>
										);
									})}
								</div>}
								{(survey.surveyType.slice(0, 4) === 'tag-' && step.type === 'tag-choose') && 
									<div className="SurveyPopup-surveyInfo">
										<p style={{color: 'red'}}>
											Vigtigt! Formuler spørgsmålet, så svaret "ja" aktiverer karantæne.
										</p>
									</div>
								}
								{(survey.surveyType.slice(0, 5) === 'tag2-' && step.type === 'tag-choose') && 
									<div className="SurveyPopup-surveyInfo">
										<p style={{color: 'red'}}>
											Vigtigt! Formuler spørgsmålet, så svaret "nej" bibeholder karantæne.<br />
											Efterfølgende dele vises kun hvis svaret er "nej".
										</p>
									</div>
								}
								<div className="SurveyPopup-surveyInfo">
									<span>Knap:</span>
									<input 
										type="text"
										name="buttonText"
										value={step.buttonText ? step.buttonText : ''}
										onChange={(event) => {updateSurvey(event, stepIndex);}}
									/>
								</div>
							</div>
						);
					})}
					<br />
					{!surveyTypeIsFromTag1 && <div 
						className={createStepBtnClass} 
						onClick={() => {createNewStep();}}
					>Opret ny del</div>}
				</div>
			</div>

			{showSurveyPreview && <SurveyPreview 
				type="box"
				surveyData={survey} 
				showSurveyPreview={setShowSurveyPreview}/>}
			
		</div>
	);
};

SurveyPopup.propTypes = {
	isCreating: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isDeleting: PropTypes.bool.isRequired,
	images: PropTypes.array.isRequired,
	tags: PropTypes.array.isRequired,
	survey: PropTypes.object.isRequired,
	updateSurvey: PropTypes.func.isRequired,
	selectSurvey: PropTypes.func.isRequired,
	createNewStep: PropTypes.func.isRequired,
	deleteStep: PropTypes.func.isRequired,
	saveSurvey: PropTypes.func.isRequired
};

export default SurveyPopup;
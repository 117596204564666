import dayjs from 'dayjs';

function getPlayerData() {
	const today = dayjs(new Date()).format('YYYY-MM-DD');
	const playerData = {
		id: 'showcase',
		cardDetention: [],
		created: today,
		gameEnded: null,
		gameStarted: today,
		gamesPlayed: [],
		introSeen: true,
		lastLogin: today,
		lockedCards: [],
		mazeId: 1,
		missionsCompleted: [],
		numberOfLogins: [],
		onboardingPlayed: true,
		secretsDiscovered: [],
		surveys: [],
		tagDetention: [],
		timeLog: {}
	};

	return playerData;
};

export {
	getPlayerData
};
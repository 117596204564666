import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './surveys-print.scss';

const SurveysPrint = ({closePopup}) => {
	let [errMsg, setErrMsg] = useState(null);
	let [successMsg, setSuccessMsg] = useState(null);

	let [email, setEmail] = useState('');
	const updateEmail = (event) => {
		setErrMsg(null);
		setSuccessMsg(null);
		setEmail(event.target.value);
	};

	return (
		<div className="SurveysPrint">
			<div className="SurveysPrint-title">{generalUiTexts.surveys}</div>
			<div className="SurveysPrint-therapist" />
			<div className="SurveysPrint-text">{renderMarkdown(generalUiTexts.surveysPopup.text)}</div>
			<div className="SurveysPrint-text2">{renderMarkdown(generalUiTexts.surveysPopup.text2)}</div>
			<form className="SurveysPrint-form">
				<input
					name="email"
					type="text"
					placeholder={generalUiTexts.surveysPopup.email}
					value={email ? email : ''} 
					onChange={(event)=>{updateEmail(event);}}
				/>
				<div 
					className={'SurveysPrint-sendBtn'} 
				>{generalUiTexts.surveysPopup.send}</div>
				{errMsg && <div className={'SurveysPrint-errorMessage'}><span>{errMsg}</span></div>}
				{successMsg && <div className={'SurveysPrint-successMessage'}><span>{successMsg}</span></div>}
			</form>
			<div className="SurveysPrint-closeBtn" onClick={closePopup}>{generalUiTexts.close}</div>
		</div>
	);
};

SurveysPrint.propTypes = {
	closePopup: PropTypes.func.isRequired
};

export default SurveysPrint;
import React from 'react';
import PropTypes from 'prop-types';
import AreaPopup from './area-popup';
import './areas.scss';

const Areas = (props) => {
	let {
		isEditing, 
		isSaving,
		isCreating,
		areas,
		selectedArea,
		selectArea,
		updateArea, 
		saveArea,
		createNewArea,
		goToPage,
	} = props;

	return (
		<div className="Areas">
			<div className="Areas-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>

			{/* Areas */}
			<div className="Areas-section">
				<div className="Areas-title">Områder:</div>
				<div className="Areas-header">
					<div className="Areas-areaId">Id</div>
					<div className="Areas-areaTitle">Titel</div>
					<div className="Areas-areaTitle">Billede</div>
					<div className="Areas-areaPortal">Næste område</div>
					<div className="Areas-areaKeys">Kræver nøgler</div>
				</div>
				{areas.map((area, index) => {
					let nextArea = '-';
					if (area.portal && area.portal.nextAreaId) {
						if (area.portal.nextAreaId === 'exit') {
							nextArea = 'Udgang';
						} else {
							nextArea = areas.find((a) => {return a.id === area.portal.nextAreaId;}).title;
						}
					}
					return (
						<div key={index} className="Areas-row" onClick={() => {selectArea(area.id);}} >
							<div className="Areas-areaId">{area.id}</div>
							<div className="Areas-areaTitle">{area.title}</div>
							<div className="Areas-areaTitle">{(area.imageFile ? area.imageFile : '-')}</div>
							<div className="Areas-areaPortal">{nextArea}</div>
							{area.portal && <div className="Areas-areaKeys">{area.portal.keys}</div>}
						</div>
					);
				})}
				<div 
					className={'Areas-newAreaBtn' + (isCreating ? ' Areas-newAreaBtn--saving' : '') } 
					onClick={() => {createNewArea();}}
				>Opret nyt område</div>
			</div>

			{/* Edit area popup */}
			{selectedArea &&
				<AreaPopup 
					isEditing={isEditing}
					isSaving={isSaving}
					selectedArea={selectedArea} 
					areas={areas}
					updateArea={updateArea} 
					selectArea={selectArea}
					saveArea={saveArea}
				/>
			}

		</div>
	);
};

Areas.defaultProps = {
	selectedArea: null
};

Areas.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	areas: PropTypes.array.isRequired,
	selectedArea: PropTypes.object,
	selectArea: PropTypes.func.isRequired,
	updateArea: PropTypes.func.isRequired,
	saveArea: PropTypes.func.isRequired,
	createNewArea: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Areas;
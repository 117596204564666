/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as KeyHoleImg} from 'assets/images/portal/key-hole.svg';
import {ReactComponent as KeyHoleUnlockedImg} from 'assets/images/portal/key-hole-unlocked.svg';
import './portal.scss';
import { generalUiTexts } from 'data/ui-texts';

const Portal = (props) => {
	let {
		mazeId,
		keys, 
		unlockedKeys, 
		animateKey, 
		animatePortal, 
		portalIsOpen,
		isEndOfGame,
		showNextBtn,
		showPortalFeedback,
		handleClosePortalPopup
	} = props;

	return (
		<div className="Portal">
			<div className="Portal-ground" />

			{/* Keys */}
			{keys && <div className={'Portal-keyHoles Portal-keyHoles--' + keys}>
				{[...Array(keys)].map((_, index) => {
					let keyHoleClass = 'Portal-keyHole Portal-keyHole--' + (index + 1);
					if ((index + 1) < unlockedKeys) keyHoleClass += ' Portal-keyHole--unlocked';
					if ((index + 1) === unlockedKeys) {
						keyHoleClass += ' Portal-keyHole--' + (animateKey ? 'animate' : 'unlocked');
					}
					return (
						<div key={index} className={keyHoleClass}>
							<div className="Portal-keyHoleGlow" />
							{((index + 1) <= unlockedKeys ? <KeyHoleUnlockedImg /> : <KeyHoleImg />)}
						</div>
					);
				})}
			</div>}

			{/* Portal */}
			<div className={'Portal-portal Portal-portal--' + mazeId}>
				<div className={'Portal-gate' + (animatePortal ? ' animate' : '') + (portalIsOpen ? ' open' : '')} />
			</div>

			{/* Walls */}
			<div className={'Portal-maze left' + (animatePortal ? ' animate' : '') + (portalIsOpen ? ' open' : '')} />
			<div className={'Portal-maze right' + (animatePortal ? ' animate' : '') + (portalIsOpen ? ' open' : '')} />

			{/* Next btn */}
			{showNextBtn && <div className="Portal-nextBtn" onClick={() => {
				if (isEndOfGame ? handleClosePortalPopup(true) : showPortalFeedback());
			}}><span>{generalUiTexts.continue}</span></div>}
		</div>				
	);
};

Portal.propTypes = {
	mazeId: PropTypes.number.isRequired,
	keys: PropTypes.number,
	unlockedKeys: PropTypes.number,
	animateKey: PropTypes.bool.isRequired,
	animatePortal: PropTypes.bool.isRequired,
	portalIsOpen: PropTypes.bool.isRequired,
	isEndOfGame: PropTypes.bool.isRequired,
	showNextBtn: PropTypes.bool.isRequired,
	showPortalFeedback: PropTypes.func.isRequired,
	handleClosePortalPopup: PropTypes.func.isRequired
};

export default Portal;
import dayjs from 'dayjs';

/**
 * Calculate users stats
 * @param {array} users 
 */
function calculateUsersStats(users) {
	let usersStats = [];
	users.forEach((user) => {
		let userObj = {
			userId: user.id,
			created: (user.created ? user.created : ''),
			started: (user.gameStarted ? user.gameStarted : '-'),
			lastLogin: (user.lastLogin ? user.lastLogin : '-'),
			ended: (user.gameEnded ? 'yes' : '-'),
			mazeId: user.mazeId,
			totalTime: 0,
		};
		if (!user.gameStarted && user.gamesPlayed && user.gamesPlayed.length > 0) {
			userObj.started = user.gamesPlayed[0];
		}
		if (user.gameEnded && typeof user.gameEnded === 'string' && user.gameEnded.length === 10) {
			userObj.ended = user.gamEnded;
		}

		// Time logged per week 
		for (let i = 1; i <= 52; i++) {userObj['week' + i] = 0;}
		if (user.created && user.lastLogin && user.timeLog) {
			let totalTime = 0;
			let weekTimes = new Array(52).fill(0);
			let startDay = dayjs(user.created);
			let endDay = dayjs(user.lastLogin);
			const numberOfDays = endDay.diff(startDay, 'day') + 1;
			for (let day = 0; day < numberOfDays; day++) {
				const currentDay = startDay.add(day, 'day');
				const weekIndex = Math.floor(day / 7.);
				if (user.timeLog.hasOwnProperty(currentDay.format('YYYY-MM-DD'))) {
					
					totalTime += user.timeLog[currentDay.format('YYYY-MM-DD')];
					if (weekIndex < 52) {
						weekTimes[weekIndex] += user.timeLog[currentDay.format('YYYY-MM-DD')];
					}
				}
			}
			userObj.totalTime = Math.floor(totalTime / (60 * 1000.));
			for (let i = 1; i <= 52; i++) {
				userObj['week' + i] = Math.floor(weekTimes[i - 1] / (60 * 1000.));
			}
		}

		// Sessions started per week
		for (let i = 1; i <= 52; i++) {userObj['weekLogins' + i] = 0;}
		if (user && user.numberOfLogins && user.numberOfLogins.length > 0) {
			let weekLogins = new Array(52).fill(0);
			let startDay = dayjs(user.created);
			let endDay = dayjs(user.lastLogin);
			const numberOfDays = endDay.diff(startDay, 'day') + 1;
			for (let day = 0; day < numberOfDays; day++) {	
				const currentDay = startDay.add(day, 'day');
				const weekIndex = Math.floor(day / 7.);
				const numberOfLogins = user.numberOfLogins.filter((loginDate) => {
					return loginDate === currentDay.format('YYYY-MM-DD');
				}).length;
				weekLogins[weekIndex] += numberOfLogins;
			}
			for (let i = 1; i <= 52; i++) {
				userObj['weekLogins' + i] = weekLogins[i - 1];
			}
		}

		// Missions completed per week
		usersStats.push(userObj);
		for (let i = 1; i <= 52; i++) {userObj['weekMissions' + i] = 0;}
		if (user && user.missionsCompleted && user.missionsCompleted.length > 0) {
			let weekMissions = new Array(52).fill(0);
			let startDay = dayjs(user.created);
			let endDay = dayjs(user.lastLogin);
			const numberOfDays = endDay.diff(startDay, 'day') + 1;
			for (let day = 0; day < numberOfDays; day++) {	
				const currentDay = startDay.add(day, 'day');
				const weekIndex = Math.floor(day / 7.);
				const numberOfMissions = user.missionsCompleted.filter((mission) => {
					return (
						mission.hasOwnProperty('completed') && 
						mission.completed === currentDay.format('YYYY-MM-DD')
					);
				}).length;
				weekMissions[weekIndex] += numberOfMissions;
			}
			for (let i = 1; i <= 52; i++) {
				userObj['weekMissions' + i] = weekMissions[i - 1];
			}
		}
	});
	return usersStats;
};

/**
 * Get surveys overview data sheet
 * @param {array} surveysData 
 * @returns 
 */
function getSurveysOverviewSheet(surveysData) {
	let columns = [
		{value: 'id', label: 'Id'},
		{value: 'type', label: 'Type'},
		{value: 'maze', label: 'Maze #'},
		{value: 'step1a', label: 'Del 1 a'},
		{value: 'step1b', label: 'Del 1 b'},
		{value: 'step2a', label: 'Del 2 a'},
		{value: 'step2b', label: 'Del 2 b'},
		{value: 'step3a', label: 'Del 3 a'},
		{value: 'step3b', label: 'Del 3 b'},
		{value: 'step4a', label: 'Del 4 a'},
		{value: 'step4b', label: 'Del 4 b'},
		{value: 'step5a', label: 'Del 5 a'},
		{value: 'step5b', label: 'Del 5 b'},
	];

	let data = [];
	surveysData.forEach((survey) => {
		let surveyObj = {
			id: survey.id,
			type: survey.surveyType,
			maze: survey.mazeId
		};
		survey.steps.forEach((step, index) => {
			surveyObj['step' + (index + 1) + 'a'] = '-';
			surveyObj['step' + (index + 1) + 'b'] = '-';
			if (step.text) surveyObj['step' + (index + 1) + 'a'] = step.text;
			if (step.options) {
				let options = '';
				step.options.forEach((option) => {
					if (options.length === 0) {
						options = option.title;
					} else {
						options += ', ' + option.title;
					}
				});
				surveyObj['step' + (index + 1) + 'b'] = options;
			}
		});
		data.push(surveyObj);
	});

	return {
		id: 'surveys',
		name: 'Surveys',
		columns: columns,
		data: data
	};

}

/**
 * Get sheet with user stats
 * @param {array} users 
 */
function getUsersSheet(users) {
	let columns = [
		{value: 'userId', label: 'Spiller'},
		{value: 'created', label: 'Oprettet'},
		{value: 'started', label: 'Startet'},
		{value: 'lastLogin', label: 'Sidste login'},
		{value: 'ended', label: 'Afsluttet'},
		{value: 'mazeId', label: 'Maze #'},
		{value: 'totalTime', label: 'Tid i alt'},
	];
	for (let i = 1; i <= 52; i++) {columns.push({value: 'week' + i, label: 'Uge ' + i + ' (tid)'});}
	for (let i = 1; i <= 52; i++) {columns.push({value: 'weekLogins' + i, label: 'Uge ' + i + ' (sessioner)'});}
	for (let i = 1; i <= 52; i++) {columns.push({value: 'weekMissions' + i, label: 'Uge ' + i + ' (missioner)'});}
	return {
		id: 'users',
		name: 'Spillere',
		columns: columns,
		data: calculateUsersStats(users)
	};
}

/**
 * Get data for specific survey
 * @param {object} surveyData 
 * @param {array} users 
 * @returns 
 */
function getSurveyData(surveyData, users) {
	let surveyStats = [];
	users.forEach((user) => {
		if (user.surveys && user.surveys.some((s) => {return s.id === surveyData.id;})) {
			user.surveys.filter((s) => {return s.id === surveyData.id;}).forEach((userSurveyData) => {
				let userObj = {
					userId: user.id,
					surveyDate: userSurveyData.surveyDate,
				};		
				surveyData.steps.forEach((step, index) => {
					if (step.type !== 'intro') {
						userObj['step' + (index + 1)] = '-';
						if (userSurveyData.inputs && userSurveyData.inputs.length > index) {
							userObj['step' + (index + 1)] = userSurveyData.inputs[index];
						}						
					}
				});
				surveyStats.push(userObj);
			});
		}
	});

	return surveyStats;
};

/**
 * Get sheet with stats for specific survey
 * @param {object} surveyData 
 * @param {array} users 
 * @returns 
 */
function getSurveySheet(surveyData, users) {
	let columns = [
		{value: 'userId', label: 'Spiller'},
		{value: 'surveyDate', label: 'Dato'},
	];

	if (surveyData.steps && surveyData.steps.length > 0) {
		surveyData.steps.forEach((step, index) => {
			if (step.type !== 'intro') {
				columns.push({value: 'step' + (index + 1), label: 'Del ' + (index + 1)});
			}
		});
	}

	return {
		id: surveyData.id,
		name: surveyData.id,
		columns: columns,
		data: getSurveyData(surveyData, users)
	};
}

export {
	getUsersSheet,
	getSurveysOverviewSheet,
	getSurveySheet
	
};
import appConfig from 'config/app.config';

const secretsData = (appConfig.env === 'en' 
  ? [
    {id: 1, title: 'The secret of the lake'},
    {id: 2, title: 'The secret of the tree'},
  ]
  : [
    {id: 1, title: 'Søens hemmelighed'},
    {id: 2, title: 'Træets hemmelighed'},
  ]
);

export default secretsData;

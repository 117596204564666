import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import Missions from './missions';

class MissionsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errmsg: null,
			missions: [],
			cards: [],
			selectedMission: null,
			isEditing: false,
			isSaving: false,
			isCreating: false
		};
		this.unsubscribeMissions = null;
		this.unsubscribeCards = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		Promise.all([this.subscribeToMissions(), this.subscribeToCards()]).then(() => {
			this.setState({isLoading: false});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false, errmsg: error});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeMissions) this.unsubscribeMissions();
		if (this.unsubscribeCards) this.unsubscribeCards();
	}

	/**
	 * Subscribe to missions
	 */
	subscribeToMissions = () => {
		if (this.unsubscribeMissions !== null) this.unsubscribeMissions();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeMissions = db.collection(appConfig.missionsDbName).onSnapshot((querySnapshot) => {
				let missions = [];
				if (!querySnapshot.empty) {
					missions = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						return data;						
					});
					missions = sortArrayByProperty(missions, 'id', 'ASC');
				}
				this.setState({missions: missions}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to cards
	 */
	subscribeToCards = () => {
		if (this.unsubscribeCards !== null) this.unsubscribeCards();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeCards = db.collection(appConfig.cardsDbName).onSnapshot((querySnapshot) => {
				let cards = [];
				if (!querySnapshot.empty) {
					cards = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						data.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
						return data;						
					});
				}
				this.setState({cards: cards}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Select mission
	 * @param {string} missionId 
	 */
	selectMission = (missionId) => {
		if (this.state.missions.some((mission) => {return mission.id === missionId;})) {
			let selectedMission = this.state.missions.find((mission) => {return mission.id === missionId;});
			this.setState({selectedMission});
		} else {
			this.setState({selectedMission: null, isEditing: false});
		}
	}

	/**
	 * Update mission
	 * @param {object} event 
	 */
	updateMission = (event) => {
		if (this.state.isSaving || this.state.isCreating) return;
		let name = event.target.name;
		let value = event.target.value;
		let selectedMission = JSON.parse(JSON.stringify(this.state.selectedMission));
		selectedMission[name] = value;
		this.setState({selectedMission, isEditing: true});
	}

	/**
	 * Save changes
	 */
	saveMission = () => {
		/* Save mission updates */
		if (this.state.isEditing && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {
				let missionUpdates = {title: this.state.selectedMission.title};
				const db = firebase.firestore();
				let missionId = this.state.selectedMission.id.toString();
				db.collection(appConfig.missionsDbName).doc(missionId)
					.update(missionUpdates).then(() => {
						this.setState({isEditing: false, isSaving: false, selectedMission: null});
					}, (error) => {
						console.error(error);
						this.setState({isSaving: false});
					});
			});
		}
	}

	/**
	 * Create new mission
	 */
	createNewMission = () => {
		if (this.state.isSaving || this.state.isCreating) return;

		this.setState({isCreating: true}, () => {
			/* Generate mission id */
			let missionId = this.state.missions.length + 1;

			/* Check mission id does not already exist */
			let missionIdExists = this.state.missions.some((mission) => {return mission.id === missionId;});;
			let numberOfIterations = 0;
			while (missionIdExists && numberOfIterations < 1000) {
				numberOfIterations += 1;
				missionId = missionId + 1;
				// eslint-disable-next-line no-loop-func
				missionIdExists = this.state.missions.some((mission) => {return mission.id === missionId;});
			}

			if (!missionIdExists) {
				/* Create new mission */
				let newMissionObj = {id: missionId, title: 'Mission ' + missionId};
				let missionRefId = missionId.toString();
				const db = firebase.firestore();
				db.collection(appConfig.missionsDbName).doc(missionRefId).set(newMissionObj).then(() => {
					this.setState({isCreating: false});
				}, (error) => {
					console.error(error);
					this.setState({isCreating: false});
				});
			} else {
				console.error('Error creating mission');
			}
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		return (
			<Missions 
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				isCreating={this.state.isCreating}
				missions={this.state.missions}
				cards={this.state.cards}
				selectedMission={this.state.selectedMission}
				selectMission={this.selectMission}
				updateMission={this.updateMission}
				saveMission={this.saveMission}
				createNewMission={this.createNewMission}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

MissionsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default MissionsController;
import React from 'react';
import PropTypes from 'prop-types';
import MissionPopup from './mission-popup';
import './missions.scss';

const Missions = (props) => {
	let {
		isEditing, 
		isSaving,
		isCreating,
		missions,
		cards,
		selectedMission,
		selectMission,
		updateMission, 
		saveMission,
		createNewMission,
		goToPage,
	} = props;

	return (
		<div className="Missions">
			<div className="Missions-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>

			{/* Missions */}
			<div className="Missions-section">
				<div className="Missions-title">Missioner:</div>
				<div className="Missions-header">
					<div className="Missions-missionId">Id</div>
					<div className="Missions-missionTitle">Titel</div>
					<div className="Missions-missionCards">Kort</div>
				</div>
				{missions.map((mission, index) => {
					let cardsUsingMission = cards.filter((card) => {
						return (
							(card.hasOwnProperty('answerA') && 
							card.answerA && card.answerA.hasOwnProperty('mission') && 
							card.answerA.mission && 
							card.answerA.mission.missionId === mission.id) ||
							(card.hasOwnProperty('answerB') && 
							card.answerB && card.answerB.hasOwnProperty('mission') && 
							card.answerB.mission && 
							card.answerB.mission.missionId === mission.id)
						);
					});
					return (
						<div key={index} className="Missions-row" onClick={() => {selectMission(mission.id);}} >
							<div className="Missions-missionId">{mission.id}</div>
							<div className="Missions-missionTitle">{mission.title}</div>
							<div className="Missions-missionCards">
								{cardsUsingMission.map((card, index) => {
									let options = '';
									if (
										card.hasOwnProperty('answerA') && 
										card.answerA && 
										card.answerA.hasOwnProperty('mission') && 
										card.answerA.mission && 
										card.answerA.mission.missionId === mission.id
									) {
										options = ' (A)';
									}
									if (
										card.hasOwnProperty('answerB') && 
										card.answerB && 
										card.answerB.hasOwnProperty('mission') && 
										card.answerB.mission && 
										card.answerB.mission.missionId === mission.id
									) {
										if (options.length === 0) {
											options = '(B)';
										} else {
											options = '(A+B)';
										}
									}
									if (index + 1 < cardsUsingMission.length) options = options + ', ';

									return <span key={index}>{card.id} {options}</span>;
								})}
							</div>
						</div>
					);
				})}
				<div 
					className={'Missions-newMissionBtn' + (isCreating ? ' Missions-newMissionBtn--saving' : '') } 
					onClick={() => {createNewMission();}}
				>Opret ny mission</div>
			</div>

			{/* Edit mission popup */}
			{selectedMission &&
				<MissionPopup 
					isEditing={isEditing}
					isSaving={isSaving}
					selectedMission={selectedMission} 
					updateMission={updateMission} 
					selectMission={selectMission}
					saveMission={saveMission}
				/>
			}

		</div>
	);
};

Missions.defaultProps = {
	selectedMission: null
};

Missions.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	missions: PropTypes.array.isRequired,
	cards: PropTypes.array.isRequired,
	selectedMission: PropTypes.object,
	selectMission: PropTypes.func.isRequired,
	updateMission: PropTypes.func.isRequired,
	saveMission: PropTypes.func.isRequired,
	createNewMission: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Missions;